import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminProfileService } from 'src/app/services/admin-profile/admin-profile.service';
import { AuthServiceService } from 'src/app/services/auth-service/auth-service.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit{
  /**
   * Property to display logout popup
   */
  public logout = false;

  /**
   * Property to handle profile data 
   */
  adminProfile:Profile={
    firstName:'',
    lastName:'',
    photo:'',
    role:{
      roleId:'',
      roleType:''
    }
  }

  showLeadsSubMenu = false;


  constructor(private router:Router, private profile:AdminProfileService, private authService:AuthServiceService) {
    
    
  }

  ngOnInit(): void {
    this.getProfile();
  }

  /**
   * Property to logout 
   */
  logoutAdmin(){
    messageAlert('logout','success')
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  getNewToken(){
    this.authService.getNewToken().subscribe({
      next: (data: object) => {
        const logindata = data as LoginApiResponse; // Type assertion
        this.authService.setToken(logindata.data.accessToken);
        this.authService.setToken(logindata.data.refreshToken);
      },
      error: () => {
        customAlert("invalidCredentials",'warning');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    })
  }

  getProfile() {
    this.profile.getAdminProfile().subscribe({
      next: (data: ApiResponse) => {
        this.profile.adminProfile = data.data;
        this.adminProfile = this.profile.adminProfile;
      },
      error: (err) => {
        if(err.status==401 && localStorage.getItem('isLoggedIn')=='true'){
          this.getNewToken();
        }else{
          messageAlert("invalidCredentials",'warning');
          localStorage.clear();
          this.router.navigate(['/login']);
        }

      },
    })
  }

  openProfileForm(){
    this.router.navigate(['/personal-details']);
  }
}

interface Profile{
  firstName:string,
  lastName:string,
  photo:string,
  role:{
    roleId:string,
    roleType:string
  }
}




interface ApiResponse {
  success: boolean;
  message: string;
  data: Profile;
  meta: Meta;
}

interface LoginApiResponse {
  success: boolean;
  message: string;
  data: LoginData;
  meta: Meta;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

interface LoginData {
  _id: string;
  role: {
    roleId: {
      _id: string;
      isDefault: boolean;
      role: string;
      __v: number;
      createdAt: string;
      description: string;
      name: string;
      resources: string[];
      status: string;
      updatedAt: string;
    };
    roleType: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  createdAt: string;
  photo: string;
  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    geo: number[];
  };
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
  dob: string;
  accessToken: string;
  refreshToken: string;
}