<section>
    <div class="sidebar">
        <div class="logo-container">
            <img src="assets/images/Layer_1.svg" alt="logo">
            <span class="logo-text">PHOTOWONDER</span>
        </div>
        <ul class="menu">
            <!-- <li>
                <a class="menu-item" routerLink="/orders" routerLinkActive="active">
                    <img src="assets/images/Purchase Order.svg" alt="" class="sidebar-image">
                    <span class="menu-text">Orders</span>
                </a>
            </li> -->
            <li>
                <a class="menu-item" routerLink="/service-management" routerLinkActive="active">
                    <img src="assets/images/Miscellaneous services.svg" alt="" class="sidebar-image">
                    <span class="menu-text">Service Management</span>
                </a>
            </li>
            <li>
                <a class="menu-item" routerLink="/organization-management" routerLinkActive="active" >
                    <!-- (click)="showLeadsSubMenu = !showLeadsSubMenu" -->
                    <img src="assets/images/Home repair service.svg" alt="" class="sidebar-image">
                    <span class="menu-text">Organization Management</span>
                </a>
                <!-- <ul class="sub-menu-item" *ngIf="showLeadsSubMenu" >
                    <li style="list-style: none;">
                        <a class="sub-menu-item" routerLink="/organization-management/leads" routerLinkActive="active">
                            <img src="assets/images/user-128.svg" alt="" class="sidebar-image">
                            <span class="sub-menu-text">Leads</span>
                        </a>
                    </li>
                </ul> -->
            </li>
            <li>
                <a class="menu-item" routerLinkActive="active" routerLink="/billing-central">
                    <img src="assets/images/Bill.svg" alt="" class="sidebar-image">
                    <span class="menu-text">Billing Central</span>
                </a>
            </li>
            <!-- <li>
                <a class="menu-item" routerLinkActive="active" routerLink="/team-users">
                    <img src="assets/images/Groups.svg" alt="" class="sidebar-image">
                    <span class="menu-text">Team Users</span>
                </a>
            </li> -->
        </ul>
        <div class="profile-container" *ngIf="adminProfile.firstName">
            <div class="profile">PROFILE</div>
            <div class="profile-image-container" (click)="openProfileForm()">
                <div class="profile-text-container"><span>{{(adminProfile.firstName)[0]+adminProfile.lastName[0]?adminProfile.firstName[0]+adminProfile.lastName[0]:'  '}}</span></div>
                <div class="profile-name">
                    <div class="name">{{adminProfile.firstName+" "+adminProfile.lastName?adminProfile.firstName+" "+adminProfile.lastName:'ADMIN'}}</div>
                    <div class="role">{{adminProfile.role.roleType?(adminProfile.role.roleType==='SUPERADMIN'?'Super Admin':adminProfile.role.roleType):'Super Admin'}}</div>
                </div>
            </div>
            <button class="logout-button" (click)="logout=true" >
                <img src="assets/images/Vector.svg" alt="" class="logout-icon">
                <span>Log out</span>
            </button>
        </div>
    </div>
</section>

<app-modal-popup *ngIf="logout" titleValue="Logout" (closeClick)="logout = false" (outsideClick)="logout = false"
    [logout]="true" (logoutButton)="logoutAdmin()">
    <p class="modal-text">Are you sure, you wanna <span style="font-weight: bold;">logout</span>?</p>
</app-modal-popup>