<div class="main-container">
    <div class="sidebar-container">
        <app-sidebar></app-sidebar>
    </div>

    <div class="table-container">
        <div class="heading-container">
            <div>
                <h2 class="module-heading">Service Management</h2>
                <span class="module-sub-heading">View or update your <span style="font-weight: bold;">service
                        details</span> here.</span>
            </div>
            <div class="button-container">
                <div class="search-container">
                    <input type="text" class="search-bar" placeholder="Search" (keydown.enter)="onSearch($event)">
                    <span class="search-icon"></span>
                </div>
                <button class="black-button" (click)="onAddService()">Add Service</button>
            </div>
        </div>
        <div>
            <app-table 
            [tableData]="serviceManagementTableData" 
            [showActions]="false" 
            [showActions]="true"
            (actionButtonHandler)="onActionButtonHandler($event)"
            [showPagination]="true"
            [tableDataSize]="tableDataSize"
            [pageSize]="pageLimit"
            [pageNumber]="pageNumber"
            (fetchAllWithPagination)="fetchAllWithPagination($event)"></app-table>
        </div>
    </div>
</div>

<app-modal-popup *ngIf="deleteService" titleValue="Delete" (closeClick)="deleteService = false"
    (outsideClick)="deleteService = false">
    <p class="modal-text">Are you sure, you wanna <span style="font-weight: bold;">delete Organization "John
            Merchants"</span>?</p>
</app-modal-popup>

<app-modal-popup *ngIf="addService" titleValue="New Service" (closeClick)="addService = false" [addPopup]="true"
    (outsideClick)="addService = false" (confirmButton)="addServiceMangement(serviceData)">
    <div>
        <form>
            <div class="form-group">
                <label class="form-label" for="serviceName">Service Name</label>
                <input type="text" class="form-control" placeholder="Name" id="serviceName" name="serviceName" (change)="getServiceValue($event)" required>
            </div>
            <div class="form-group">
                <label class="form-label" for="serviceId">Service ID</label>
                <input type="text" class="form-control" placeholder="4253645758" id="serviceId" name="serviceId" (change)="getServiceValue($event)" required>
            </div>
            <div class="field-container">
                <div class="form-group">
                    <label class="form-label" for="turnaround">Turnaround Time</label>
                    <input type="number" class="form-control" id="turnaround" placeholder="00 hrs" name="turnaroundTime" (change)="getServiceValue($event)" required>
                </div>
                <div class="form-group">
                    <label class="form-label" for="rate">Rate</label>
                    <div class="field-container">
                        <div class="right-border">$</div>
                        <input type="number" class="form-control left-border" id="rate" placeholder="00:00" name="rate" (change)="getServiceValue($event)" required>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="description">Description</label>
                <textarea type="text" class="form-control description-size" placeholder="Description" id="description" name="description" required (change)="getServiceValue($event)"></textarea>
            </div>
        </form>
    </div>

</app-modal-popup>

<app-modal-popup *ngIf="editService" (closeClick)="editService = false" [addPopup]="true"
    (outsideClick)="editService = false" titleValue="Edit Service" (confirmButton)="editServiceMangement(serviceData)">
    <div>
        <form>
            <div class="form-group">
                <label class="form-label" for="serviceName">Service Name</label>
                <input type="text" class="form-control" placeholder="Name" id="serviceName" name="serviceName" required (change)="getServiceValue($event)" [value]="serviceData.serviceName">
            </div>
            <div class="form-group">
                <label class="form-label" for="serviceId">Service ID</label>
                <input type="text" class="form-control" placeholder="4253645758" id="serviceId" name="serviceId" required (change)="getServiceValue($event)" [value]="serviceData.serviceId">
            </div>
            <div class="field-container">
                <div class="form-group">
                    <label class="form-label" for="turnaround">Turnaround Time</label>
                    <input type="text" class="form-control" id="turnaround" placeholder="00 hrs" name="turnaroundTime" required (change)="getServiceValue($event)" [value]="serviceData.turnaroundTime.time?serviceData.turnaroundTime.time:''">
                </div>
                <div class="form-group">
                    <label class="form-label" for="rate">Rate</label>
                    <div class="field-container">
                        <div class="right-border">$</div>
                        <input type="text" class="form-control left-border" id="rate" placeholder="00.00" name="rate" required (change)="getServiceValue($event)" [value]="serviceData.rate.amount?serviceData.rate.amount:''">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="description">Description</label>
                <textarea type="text" class="form-control description-size" placeholder="Description" id="description" name="description" required (change)="getServiceValue($event)" [value]="serviceData.description?serviceData.description:''"></textarea>
            </div>

        </form>
    </div>

</app-modal-popup>

<app-modal-popup *ngIf="deletepopup" title="Logout" (closeClick)="deletepopup = false"
    (outsideClick)="deletepopup = false" titleValue="Delete" (deleteButton)="deleteServiceMangement()">
    <p class="modal-text">Are you sure, you wanna <span style="font-weight: bold;">delete service "{{serviceName}}"</span>?</p>
</app-modal-popup>