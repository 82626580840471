import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {


  /**
   * Property to get API url
   */
  private readonly apiUrl = environment.apiRoute;

  constructor(private http: HttpClient) { }

  /**
   * Method to get headers
   */
  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('@$T0ken')}`
    });
    return headers;
  }

  /**
   * Method to get data
   */
  // eslint-disable-next-line
  get(endpoint: string): Observable<any> {
    const headers = this.getHeaders();
    const url = `${this.apiUrl}${endpoint}`;
    return this.http.get(url, { headers });
  }


  /**
   * Method to post
   */
  // eslint-disable-next-line
  post(endpoint: string, data: object, searchParams?: string, page?: number, limit?: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('@$T0ken')}`
    });

    const url = searchParams
      ? `${this.apiUrl}${endpoint}?search=${searchParams}&page=${page}&limit=${limit}`
      : `${this.apiUrl}${endpoint}`;

    return this.http.post(url, data, { headers });
  }


  /**
   * Method to put
   */
  // eslint-disable-next-line
  put(endpoint: string, data: object): Observable<any> {
    const headers = this.getHeaders();
    const url = `${this.apiUrl}${endpoint}`;
    return this.http.put(url, data, { headers });
  }

  /**
   * Method to delete
   */
  // eslint-disable-next-line
  delete(endpoint: string, data: object): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('@$T0ken')}`
      }),
      body: data,
    };
    const url = `${this.apiUrl}${endpoint}`;
    return this.http.delete(url, options);

  }
}
