<div [ngClass]="{'sidebar-box': isOpen}" (click)="toggleSidebar()">
  <div class="sidebar" [ngClass]="{'open': isOpen}">
    <div class="sidebar-content">
      <div class="details">
        <ul>
          <img src="assets/images/Layer_1.svg" width="22px" height="40px" alt="logo">
          <li class="heading">PHOTOWONDER</li>
          <li class="info">PO Box 14794</li>
          <li class="info">Chicago,IL 60614</li>
        </ul>

        <ul style="padding-top: 20px;">
          <li style="text-align: end;
          color: #cfcfcf;">Invoice No.</li>
          <li class="invoice-No">{{data.invoiceNumber?data.invoiceNumber:''}}</li>
        </ul>
      </div>
      <div class="details space">
        <ul>
          <li style="
          color: #cfcfcf;">Issued on</li>
          <li class="content">{{data.timePeriod.startDate?(data.timePeriod.startDate | date):''}}</li>
        </ul>
        <ul>
          <li style="text-align: end;
          color: #cfcfcf;">Status</li>
          <li class="content">{{data.paymentStatus?data.paymentStatus:''}}</li>
        </ul>
      </div>
      <div class="details space">
        <ul>
          <li style="
          color: #cfcfcf;">Due on</li>
          <li class="content">{{data.timePeriod.endDate?(data.timePeriod.endDate | date):''}}</li>
        </ul>
        <ul>
          <li style="text-align: end;
          color: #cfcfcf;">Terms</li>
          <li class="content">Net {{ data.timePeriod.startDate?(calculateDateDifference(data.timePeriod.startDate,data.timePeriod.endDate)):''}}</li>
        </ul>
      </div>
      <div class="details space">
        <ul>
          <li class="info" style="
          color: #cfcfcf;">Invoice for</li>
          <li class="info" class="content">{{data._organization.contactPersonName?data._organization.contactPersonName:"'"}}</li>
          <li class="info">{{data._organization.address.street?data._organization.address.street:''}}</li>
          <li class="info">{{data._organization.address.city?data._organization.address.city:''
            }}, {{data._organization.address.state?data._organization.address.state:''
            }}</li>
        </ul>
      </div>
      <table class="rounded-table" *ngIf="data.invoiceLink">
        <thead>
          <tr class="row">
            <th>Service</th>
            <th>OrderNo</th>
            <th>Date</th>
            <th>Qty</th>
            <th>Rate</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let service of data.orders">
            <td>{{service._service.name?service._service.name:''}}</td>
            <td>{{service.orderNumber?service.orderNumber:''}}</td>
            <td>{{service.updatedAt | date}}</td>
            <td>{{service.billableImages}}</td>
            <td>${{service._service.rate.amount}}</td>
            <td>${{ (+service.billableImages * +service._service.rate.amount).toFixed(2) }}</td>
          </tr>
          
        </tbody>
      </table>
      <div class="balance"><span style="color:#B6BBC3;margin-right:10px;">Balance Due</span><span
          style="color:#69717C;">${{data.amount.totalAmount}}</span></div>
    </div>
    <div class="sidebar-footer">
      <div style="align-self: center;display: flex;" >
        <a *ngIf="data.invoiceLink" [href]="data.invoiceLink" target="_blank" style="margin-left: 5px;color:#1E1E1E;    text-decoration: none;display:flex;">DOWNLOAD INVOICE<img style="    padding-left: 5px;"
            src="assets/images/download.png" alt="download"></a>
      </div>
      <div>
        <button class="cancel-button" (click)="toggleSidebar()">Close</button>
      </div>
    </div>
  </div>
</div>