<section class="background-overlay">
  <div class="container">
      <div class="login-box">
          <div class="logo-container">
              <img src="assets/images/Layer_1.svg" alt="logo">
              <span class="logo-text">PHOTOWONDER</span>
          </div>
          <h2 class="heading">Password Reset Successfully</h2>
          <p class="sub-heading" >Your password has been successfully reset. You can now login with your new password.</p>
              <div class="forgot-password" *ngIf="role==='SUPERADMIN'"><a routerLink="/login" class="pass-link">Back to login</a></div>
      </div>
  </div>
</section>