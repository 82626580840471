import { Injectable } from '@angular/core';
import { NetworkService } from '../network/network.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceManagementService {

  

  /**
   * Property to hold service url
   */
  url = "/aggregation/services";

  constructor(private network: NetworkService) { }

  /**
   * Method to get service management data
   */
  getServiceMangement(page: number, limit: number, search = '') {
    return this.network.get(`${this.url}?limit=${limit}&page=${page}&search=${search}`);
  }

  /**
   * Method to delete service management data
   */
  deleteServiceMangement(data:object) {
    return this.network.delete(`${this.url}`, data);
  }

  /**
   * Method to Add service management data
   */
  addServiceMangement(data:object) {
    return this.network.post(`${this.url}`, data);
  }

  /**
   * Method to Update service management data
   */
  editServiceMangement(data:object) {
    return this.network.put(`${this.url}`, data);
  }

}
