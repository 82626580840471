<div (click)="outsideClicked()" class="rt-modal-popup">
  <div (click)="$event.stopPropagation()" class="rt-modal-content" [ngClass]="{'rt-modal-content-1' : toggleCss}">
    <div class="action">
      <h2 class="rt-modal-title">
        {{ titleValue }}
      </h2>
    </div>
    <div class="rt-modal-body mt-2">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer" *ngIf="!addPopup && !cancelPopup">
      <button class="cancel-button" (click)="closeModal()">Cancel</button>
      <button class="delete-button" *ngIf="!logout" (click)="deleteClick()">Delete</button>
      <button class="delete-button" *ngIf="logout" (click)="logoutClick()">Logout</button>
    </div>
    <div class="add-modal-footer" *ngIf="addPopup">
      <button class="cancel-button" (click)="closeModal()">Cancel</button>
      <button class="black-button" style="width: 218px;" (click)="confirmClick()">Confirm</button>
    </div>

    <div class="add-modal-footer" *ngIf="cancelPopup">
      <button class="cancel-button" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>