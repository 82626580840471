<section class="background-overlay">
    <div class="container">
        <div class="login-box">
            <div class="logo-container">
                <img src="assets/images/Layer_1.svg" alt="logo">
                <span class="logo-text">PHOTOWONDER</span>
            </div>
            <h2 class="heading">Forgot your Password</h2>
            <p class="sub-heading">No worries, we'll send you reset instructions.</p>
            <form [formGroup]="forgotPasswordForm">
                <div class="form-group">
                    <label class="form-label" for="email">Email</label>
                    <input type="email" class="form-control" id="email" placeholder="Enter your email" formControlName="email" required>
                </div>
                <button type="submit" class="btn btn-primary" (click)="forgotPassword()">Confirm</button>
                <div class="forgot-password"><a href="#" class="pass-link">Back to login</a></div>
            </form>
        </div>
    </div>
</section>