import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service/auth-service.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  /**
   * Property to hold forgot password form
   */
  forgotPasswordForm = new FormGroup({
    //eslint-disable-next-line
    email: new FormControl('', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),
  });

  constructor(private authService: AuthServiceService, private route: Router) { }

  /**
   * Method to Forgot password
   */
  public forgotPassword() {
    if(this.forgotPasswordForm.value.email==''){
      messageAlert("emailEnter",'warning');
    }else if(this.forgotPasswordForm.invalid) {
      customAlert("Please enter correct Email",'warning');
    }else{
      this.authService.forgotPassword(this.forgotPasswordForm.value.email || "")
        .subscribe({
          next: (data: object) => {
            const passworddata = data as ResetPasswordResponse;
            customAlert(passworddata.message,'success');
            this.route.navigate(['/login']);
          },
          error: (err) => {

            customAlert(err.error.message,'warning');
          }
        })
    }
  }
}

interface ResetPasswordResponse {
  success: boolean;
  message: string;
  data: {
    resetToken: string;
  };
  meta: string;
}