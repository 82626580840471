<div class="main-container">
    <div class="sidebar-container">
        <app-sidebar></app-sidebar>
    </div>

    <div class="table-container">
        <div>
            <h2>Orders</h2>
        </div>
        <app-table></app-table>
    </div>
</div>