<div class="main-container">
  <div class="sidebar-container">
      <app-sidebar></app-sidebar>
  </div>

  <div class="table-container">
      <div class="heading-container">
          <div>
              <h2 class="module-heading">Leads</h2>
              <span class="module-sub-heading">View or update your <span style="font-weight: bold;">leads
                      details</span> here.</span>
          </div>
      </div>
      <div>
          <app-table 
          [tableData]="leadsTableData" 
          [showActions]="false" 
          [dropdownButton]="true"
          (actionButtonHandler)="onActionButtonHandler($event)"
          [showPagination]="true"
          [tableDataSize]="tableDataSize"
          [pageSize]="pageLimit"
          [pageNumber]="pageNumber"
          (fetchAllWithPagination)="fetchAllWithPagination($event)"></app-table>
      </div>
  
  </div>
</div>
