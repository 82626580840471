import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrgnizationManagementService } from 'src/app/services/orgnization-management/orgnization-management.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';
import { ServiceMappingService } from 'src/app/services/service-mapping/service-mapping.service';


@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss']
})
export class EditDetailsComponent implements OnInit {

  /**
   * Property to display service popup
   */
  addService = false;
  showDropdown = false;
  showDropdownType = false;
  selectType = '';
  enableBillingModule = false;
  selectName = '';
  isChecked = false;
  editServicePopup = false;
  serviceDropdownClick: boolean[] = [];
  serviceDelete = false;
  orgnizationUserShow = false;

  /**
   * Object to hold form data
   */
  orgnizationData: OrgnizationData = {
    _id: '',
    name: '',
    industry: '',
    contactPersonName: '',
    contactPersonNumber: {
      countryCode: '+91',
      phoneNumber: ''
    },
    contactPersonEmail: '',
    billingEmail: '',
    supportEmail: '',
    address: {
      street: '',
      city: '',
      state: '',
      postCode: '',
      country: '',
      zipCode: ''
    },
    city: '',
    state: '',
    postCode: '',
    taxNumber: ''
  }

  /**
   * Property to display industry dropdown 
   */
  industry_list = [
    "Aerospace and Defense",
    "Agriculture",
    "Automotive",
    "Banking and Finance",
    "Biotechnology and Pharmaceuticals",
    "Chemicals",
    "Construction",
    "Consumer Goods",
    "Education",
    "Energy and Utilities",
    "Entertainment and Media",
    "Food and Beverage",
    "Government and Public Sector",
    "Healthcare",
    "Hospitality and Tourism",
    "Information Technology (IT)",
    "Manufacturing",
    "Non-Profit and Charity",
    "Real Estate",
    "Retail",
    "Telecommunications",
    "Transportation and Logistics"
  ]


  /**
   * Property to handle selected service
   */
  selectedService: ServiceList = {
    id: '',
    name: '',
    _id: '',
    createdAt: '',
  }

  /**
   * Property to hold delete service index
   */
  selectedIndexService = 0

  /**
   * Property to handle selected service
   */
  confirmServices: ServiceList[] = []

  /**
   * Property to hold new service data
   */
  serviceData: ServiceData = {
    serviceName: '',
    serviceId: '',
    turnaroundTime: '',
    rate: ''
  }

  /**
   * Property to hold service list
   */
  serviceList: ServiceList[] = [];

  /**
   * Property to handle user from orgnization 
   */
  userFromOrg: userFromOrg[] = [];

  constructor (private orgnizationManagementService: OrgnizationManagementService, private route: Router, private activateRoute: ActivatedRoute, private serviceMapping: ServiceMappingService) { }

  ngOnInit(): void {
    const id: string = this.activateRoute.snapshot.params['id'];
    this.getSingleOrgnizationManagement(id);
    this.getServiceList();
    this.getServiceFromOrg();
    this.getUserFromOrg();
    this.services_List=this.serviceList;
    this.checkScrollbar();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const dropdownElement = document.querySelector('.dropdown');
    if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
      this.showDropdown = false;
    }
  }
  /**
   * Property to display delete organization popup
   */
  deleteOrganization = false;

  @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef;

  isScrollbarAtEnd = true  ;

  checkScrollbar() {
    const element = this.scrollContainer.nativeElement;
    const atEnd = element.scrollTop === (element.scrollHeight - element.clientHeight);
    if(element.scrollTop === 0){
      this.isScrollbarAtEnd = !atEnd;
    }else{
      this.isScrollbarAtEnd = atEnd;
    }
  }

  /**
   * Method to delete service mapping
   */
  deleteService(index: number) {
    this.serviceDelete = false;
    const body = {
      _id: this.confirmServices[index]._MapId
    }
    this.serviceMapping.deleteServiceWithOrg(body).subscribe({
      next: (data: ApiResponse) => {
        if (data.success) {
          setTimeout(() => {
            messageAlert("deleteService", 'success');
          }, 1000);
          this.getServiceFromOrg();
        }
      },
      error: (err) => {
        customAlert(err.error.message, 'warning');
      }
    })
  }

  /**
   * Method to handle service dropdown edit
   */
  serviceDropdownValue(text: string, index: number) {
      if (text == 'delete') {
        this.serviceDropdownClick[index] = false;
        this.selectedIndexService = index;
        this.serviceDelete = true;
  
      } else if (text == 'edit') {
        this.selectName = this.confirmServices[index].name;
        this.selectType = this.confirmServices[index].id;
        this.serviceDropdownClick[index] = false;
        this.selectedIndexService = index;
        this.editServicePopup = true;
      }

  }

  onChecked(event: Event) {
    if (!(event.currentTarget as HTMLInputElement).checked) {
      this.enableBillingModule = true;
      (event.currentTarget as HTMLInputElement).checked = true;
    } 
    else{
      this.enableBillingModule = true;
      (event.currentTarget as HTMLInputElement).checked = false;
    }
  }

  onEnableBillingModule() {
    this.orgnizationData.billing = !this.orgnizationData.billing;
    this.enableBillingModule = false; 
  }

  /**
   * Method to get User according orgnization
   */
  getUserFromOrg() {
    this.orgnizationManagementService.getUserOrg({ _organisation: this.activateRoute.snapshot.params['id'] }).subscribe({
      next: (response: ApiResponse2) => {
        if (response.success) {
          response.data.map((element: userFromOrg) => {
            this.userFromOrg.push(element)
          })
        }
      },
      error: (err) => {
        customAlert(err.error.message, 'warning');
      }
    })
  }

  /**
   * Method to delete organization
   */
  deleteOrgnization(id: string | undefined) {
    this.deleteOrganization = false;
    const body = {
      _id: id
    }
    this.orgnizationManagementService.deleteOrgnization(body)
      .subscribe({
        next: (data: ApiResponse) => {
          if (data.success) {
            setTimeout(() => {
              messageAlert("deleteOrganization", 'success');
            }, 1000);

            this.route.navigate(['/organization-management']);
          }
        },
        error: (err) => {
          customAlert(err.error.message, 'warning');
        }
      })
  }

  /**
   * Method to get dropdown value
   */
  dropdownTypeValue(service: ServiceList) {

    this.selectType = service.id;
    this.selectName = service.name
    this.showDropdownType = false;
    this.selectedService = {
      id: service.id,
      name: service.name,
      createdAt: service.createdAt,
      _id: service._id
    }
  }

  /**
   * Method to get Service List
   */
  getServiceList() {
    this.serviceMapping.getServiceMapping().subscribe({
      next: (data: MyData) => {
        data.data.forEach((element: MyDataItem) => {
          this.serviceList.push({ name: element.name, id: element.serviceId, createdAt: element.createdAt, _id: element._id })
        });
      }
    })
  }

  /**
   * Method to get Service from orgnization
   */
  getServiceFromOrg() {
    this.confirmServices = [];
    const body = {
      _organisation: this.activateRoute.snapshot.params['id']
    }
    this.serviceMapping.getServiceWithOrg(body).subscribe({
      next: (data: MyData) => {
        data.data.forEach((element: MyDataItem) => {
          this.confirmServices.push({ name: element._service.name, id: element._service.serviceId, createdAt: element._service.createdAt, _id: element._service._id, _MapId: element._id })
        });
      }
    })
  }

  /**
   * Method to confirm selected service
   */
  confirmService(selectedService: ServiceList) {
    if (this.editServicePopup) {
      if(selectedService.name==''){
        return customAlert('Service already exists', 'warning');
      }
      
      const body = {
        _id: this.confirmServices[this.selectedIndexService]._MapId,
        _organisation: this.activateRoute.snapshot.params['id'],
        _service: selectedService._id
      }
      this.serviceMapping.updateServiceWithOrg(body).subscribe({
        next: (data: ApiResponse) => {
          if (data.success) {
            this.editServicePopup = false;
            this.selectedService = {
              id: '',
              name: '',
              _id: '',
              createdAt: '',
            }
            this.selectName = '';
            this.selectType = '';
            setTimeout(() => {
              messageAlert("updateService", 'success');
            }, 1000);
            this.getServiceFromOrg();
          }
        },
        error: (err) => {
          customAlert(err.error.message, 'warning');
        }
      })

    } else {
      if(selectedService.name==''){
        return customAlert('Please select service', 'warning');
      }
      
      const body = {
        _organisation: this.activateRoute.snapshot.params['id'],
        _service: selectedService._id
      }
      this.serviceMapping.postServiceWithOrg(body).subscribe({
        next: (data: ApiResponse) => {
          if (data.success) {
            this.addService = false;
            this.selectedService = {
              id: '',
              name: '',
              _id: '',
              createdAt: '',
            }
            this.selectName = '';
            this.selectType = '';
            setTimeout(() => {
              messageAlert("addService", 'success');
            }, 1000);
            this.getServiceFromOrg();
          }
        },
        error: (err) => {
          customAlert(err.error.message, 'warning');
          this.selectedService = {
            id: '',
            name: '',
            _id: '',
            createdAt: '',
          }
          this.selectName = '';
          this.selectType = '';
        }
      })

    }


  }

  /**
   * Method to get dropdown value
   */
  dropdownValue(event: string) {
    this.orgnizationData.industry = event;
    this.showDropdown = false;
  }

  /**
   * Method to get input value
   */
  getInputValue(event: Event) {
    if((event.target as HTMLInputElement).name==='contactPersonNumber'){
      this.orgnizationData.contactPersonNumber.phoneNumber = (event.target as HTMLInputElement).value;
    }else if((event.target as HTMLInputElement).name==='address'){
      this.orgnizationData.address.street = (event.target as HTMLInputElement).value;
    }else if((event.target as HTMLInputElement).name==='city'){
      this.orgnizationData.address.city = (event.target as HTMLInputElement).value;
    }else if((event.target as HTMLInputElement).name==='state'){
      this.orgnizationData.address.state = (event.target as HTMLInputElement).value;
    }else if((event.target as HTMLInputElement).name==='postCode'){
      this.orgnizationData.address.zipCode = (event.target as HTMLInputElement).value;
    }else{
      this.orgnizationData[(event.target as HTMLInputElement).name] = (event.target as HTMLInputElement).value;
    }
  }

  /**
   * Method to add service
   */
  getServiceValue(event: Event) {
    this.serviceData[(event.target as HTMLInputElement).name] = (event.target as HTMLInputElement).value
  }

  /**
   * Method to display delete organization popup
   */
  onDeleteOrganization() {
    this.deleteOrganization = true
  }
  editEnabled = true
  enableEdit() {
    this.editEnabled = false
  }


  /**
   * Method to get single orgnization
   */
  private getSingleOrgnizationManagement(id: string) {
    this.orgnizationManagementService.getSingleOrgnizationMangement(id)
      .subscribe((response: ApiResponse) => {
        if (response.data) {
          this.orgnizationData = response.data;
        }
      })
  }

  filted_industry = this.industry_list;

  searchIndusry(event: Event) {
    this.showDropdown=true;
    const searchText = (event.target as HTMLInputElement).value;
    this.filted_industry = this.industry_list.filter((item) => {
      return item.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  services_List :ServiceList[]=[];

  searchService(event: Event) {
    this.showDropdownType=true;
    this.services_List=this.serviceList;
    const searchText = (event.target as HTMLInputElement).value;
    this.services_List = this.services_List.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }


  /**
   * Method to edit organization
   */
  editOrgnization() {
    if (this.checkValidation(this.orgnizationData) !== 'false') {
      customAlert(this.checkValidation(this.orgnizationData), 'warning');
    } else if (this.textValidation(this.orgnizationData.name) && this.textValidation(this.orgnizationData.contactPersonName)) {
      messageAlert('nameValidation', 'warning')
    } else if (this.emailValidation(this.orgnizationData.contactPersonEmail)) {
      messageAlert('emailValidation', 'warning')
    }else if (this.emailValidation(this.orgnizationData.supportEmail)) {
      customAlert('Please enter valid support email', 'warning')
    }else if (this.emailValidation(this.orgnizationData.billingEmail)) {
      customAlert('Please enter valid billing email', 'warning')
    } else if (this.phoneValidation(this.orgnizationData.contactPersonNumber.phoneNumber)) {
      messageAlert('phoneValidation', 'warning')
    } else if (this.isValidTaxNumber(this.orgnizationData.taxNumber)) {
      customAlert('Invalid tax number', 'warning')
    }else if ((this.orgnizationData.taxNumber.length > 50)) {
      customAlert('Invalid tax number', 'warning')
    }else if (!(this.confirmServices.length)) {
      customAlert('Please select at least one service', 'warning')
    }else {
      const body = {
        _id: this.orgnizationData._id,
        name: this.orgnizationData.name,
        industry: this.orgnizationData.industry,
        billingEmail: this.orgnizationData.billingEmail,
        supportEmail: this.orgnizationData.supportEmail,
        address: {
          street: this.orgnizationData.address.street,
          city: this.orgnizationData.address.city,
          state: this.orgnizationData.address.state,
          country: this.orgnizationData.address.country,
          zipCode: this.orgnizationData.address.zipCode,
          geo: [72.151, 36.84784]
        },
        contactPersonName: this.orgnizationData.contactPersonName,
        contactPersonEmail: this.orgnizationData.contactPersonEmail,
        contactPersonNumber: {
          countryCode: this.orgnizationData.contactPersonNumber.countryCode?this.orgnizationData.contactPersonNumber.countryCode:'+1',
          phoneNumber: this.orgnizationData.contactPersonNumber.phoneNumber
        },
        taxNumber: this.orgnizationData.taxNumber,
        billing: this.orgnizationData.billing
      }

      this.orgnizationManagementService.editOrgnization(body)
        .subscribe({
          next: (data: ApiResponse) => {
            if (data.success) {
              setTimeout(() => {
                messageAlert("updateOrgnization", 'success');
              }, 1000);

              this.route.navigate(['/organization-management']);
            }else{
              customAlert(data.message, 'warning');
            }
          },
          error: (err) => {
            customAlert(err.error.message, 'warning');
          }
        })
    }

  }

    /**
   * Method to select country code
   */
    selectedCountryCode(countryCode: string) {
      this.orgnizationData.contactPersonNumber.countryCode = countryCode;
    }

       /**
   * Method to select country code
   */
       selectedCountries(country: string) {
        this.orgnizationData.address.country = country;
      }

  /**
   * Method to fill all validation
   */
  checkValidation(data: OrgnizationData) {
    if (data.name === '') {
      return 'Please enter organization name';
    } else if (data.industry === '') {
      return 'Please select industry';
    } else if (data.billingEmail === '') {
      return 'Please enter billing email';
    } else if (data.supportEmail === '') {
      return 'Please enter support email';
    }  else if (data.address.street === '') {
      return 'Please enter organization address';
    }else if (data.address.city === '') {
      return 'Please enter city';
    } else if (data.address.state === '') {
      return 'Please enter state';
    } else if (data.address.postCode === '') {
      return 'Please enter postcode';
    } else if (data.taxNumber === '') {
      return 'Please enter organization tax number';
    } else if (data.contactPersonName === '') {
      return 'Please enter contact person name';
    } else if (data.contactPersonNumber.phoneNumber === '') {
      return 'Please enter contact person number';
    } else if (data.contactPersonEmail === '') {
      return 'Please enter contact person email';
    } else if (this.emailValidation(data.supportEmail)) {
      return 'Please enter valid support email';
    } else if (this.emailValidation(data.billingEmail)) {
      return 'Please enter valid billing email';
    } else if (this.isValidTaxNumber(data.taxNumber)) {
      return 'Invalid organization tax number';
    } else if (data.taxNumber.length > 50) {
      return 'Invalid organization tax number';
    } else {
      return 'false';
    }
  }

  /**
   * Method to open add service
   */
  openAddServices(){
    if(!this.editEnabled){
      this.addService = true
      this.services_List=this.serviceList;
    }

  }


  /**
   * Method to validate text
   */
  textValidation(text: string): boolean {
    const textValue = text.trim();
    if (textValue == '' || textValue.length > 50) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Method to validate tax number
   */
  isValidTaxNumber(taxNumber:string) {
    // Regular expression to match only capital letters or numbers
    const regex = /^[A-Za-z0-9]+$/;
    if(regex.test(taxNumber)) {
      return false;
    }else{
      return true;
    }
  }

  /**
   * Method to validate email
   */
  emailValidation(email: string): boolean {
    const textValue = email.trim();
    // eslint-disable-next-line no-useless-escape
    const pattern = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (textValue.match(new RegExp(pattern)) == null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Method to validate phone
   */
  phoneValidation(phone: string): boolean {
    const textValue = phone.trim();
    if (textValue == '' || textValue.length > 10 || textValue.length < 10) {
      return true;
    } else {
      return false;
    }
  }

}


interface Address {
  street: string,
  city: string,
  state: string,
  country: string,
  postCode: string,
  zipCode: string
}
interface OrgnizationData {
  name: string;
  industry: string;
  contactPersonName: string;
  contactPersonNumber: Phone;
  contactPersonEmail: string;
  billingEmail: string;
  supportEmail: string;
  address: Address;
  city: string;
  state: string;
  postCode: string;
  country?: string;
  taxNumber: string;
  billing?: boolean;
  [key: string]: string | undefined | number | boolean | null | object | string[];
  _id?: string | undefined;
}

interface Phone {
  countryCode: string,
  phoneNumber: string
}

interface ApiResponse {
  success: boolean;
  message: string;
  data: OrgnizationData;
  meta: Meta;
}

interface ApiResponse2 {
  success: boolean;
  message: string;
  data: userFromOrg[];
  meta: Meta;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

interface ServiceData {
  serviceName: string,
  serviceId: string,
  turnaroundTime: string,
  rate: string,
  [key: string]: string | undefined
}

interface MyData {
  success: boolean;
  message: string;
  data: MyDataItem[];
  meta: {
    currentPage: number;
    totalPage: number;
    totalRecords: number;
  };
}

interface MyDataItem {
  turnaroundTime: {
    time: number;
    units: string;
  };
  rate: {
    amount: number;
    units: string;
  };
  createdBy: {
    _id: string;
    role: string;
  };
  _id: string;
  name: string;
  serviceId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  _service: MyDataItem
}

interface ServiceList {
  name: string,
  id: string,
  createdAt: string,
  _id: string,
  _MapId?: string
}

interface userFromOrg {
  role?: {
    roleId?: string;
    roleType: string;
  };
  _id?: string;
  _organisation?: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: {
    countryCode?: string;
    phoneNumber?: string;
    _id?: string;
  };
  status?: string;
  createdAt?: string;
  updatedAt?: string;
}