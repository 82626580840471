<section class="background-overlay">
    <div class="container">
        <div class="login-box">
            <div class="logo-container">
                <img src="assets/images/Layer_1.svg" alt="logo" height="42.27px" width="27.35px">
                <span class="logo-text">PHOTOWONDER</span>
            </div>
            <h2 class="heading">Welcome to PhotoWonder</h2>
            <p class="sub-heading">Enter your credentials to access your account.</p>
            <form [formGroup]="loginForm">
                <div class="form-group">
                    <label class="form-label" for="email">Email</label>
                    <input type="email" class="form-control" id="email" formControlName="email"
                        placeholder="Enter your email" required>
                </div>
                <div class="form-group">
                    <label class="form-label" for="password">Password</label>
                    <span style="position: relative;">
                        <input *ngIf="togglePassword" type="password" class="form-control" id="password" formControlName="password"
                        placeholder="Enter your password" required style="padding-right: 30px;">
                        <input *ngIf="!togglePassword" type="text" class="form-control" id="password" formControlName="password"
                        placeholder="Enter your password" required style="padding-right: 30px;">
                        <i *ngIf="togglePassword" class="fa fa-eye-slash" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;color:#919191;" (click)="togglePasswordFn()"></i>
                        <i *ngIf="!togglePassword" class="fa fa-eye" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; color:#919191;" (click)="togglePasswordFn()"></i>
                    </span>
                </div>
                <div class="checkbox-container">
                    <input type="checkbox" name="rememberMe" (click)="keepLoggedIn=!keepLoggedIn" id="rememberMe" class="checkbox" >
                    <span class="checkbox-text" >Keep me logged in</span>
                </div>
                <button type="button" class="btn btn-primary" (click)="loginSubmit()">Sign in</button>
                <div class="forgot-password"><a routerLink="/forgot-password" class="pass-link">Forgot Password?</a>
                </div>
            </form>
        </div>
    </div>
</section>