import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';

@Injectable()
export class LoaderInterceptorInterceptor implements HttpInterceptor {

  constructor(private spinner: NgxSpinnerService) {}

  
  intercept(request: HttpRequest<Response>, next: HttpHandler): Observable<HttpEvent<Response>> {
    this.spinner.show();
    return next.handle(request).pipe(
      finalize(() => {
        this.spinner.hide();
      })
    );
  }
}

interface Address {
  street: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  geo: [number, number];
  _id: string;
}

interface ContactPerson {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface CreatedBy {
  _id: string;
  role: string;
}

interface Data {
  createdBy: CreatedBy;
  _id: string;
  name: string;
  industry: string;
  billingEmail: string;
  supportEmail: string;
  address: Address;
  taxNumber: string;
  billing: boolean;
  email: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  contactPerson: ContactPerson[];
  totalUsers: number;
  totalServices: number;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

interface Response {
  success: boolean;
  message: string;
  data: Data[];
  meta: Meta;
}
