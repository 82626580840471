<div class="main-container" >
    <div class="sidebar-container">
        <app-sidebar></app-sidebar>
    </div>

    <div class="table-container">
        <div class="heading-container">
            <div>
                <h2 class="module-heading">Organization Management</h2>
                <div class="module-internal-heading">
                    <span class="module-sub-heading">Add your <span style="font-weight: bold;">new organization
                            details</span> here.</span>

                </div>
            </div>
            <div class="button-container">
                <button class="black-button button-width" (click)="addOrgnization(orgnizationData)">Add
                    Organization</button>
            </div>
        </div>
        <div class="main-detail-container">
            <div class="form-container" #scrollContainer (scroll)="checkScrollbar()">
                <form>
                    <div class="bottom-border d-flex">
                        <div class="form-group" style="width: 46%;">
                            <label class="form-label" for="organizationName">Organization Name</label>
                            <input type="text" class="form-control" placeholder="Name" id="organizationName" name="name"
                                (change)="getInputVale($event)" required>
                        </div>
                        <div class="form-group" style="width: 50%;">
                            <label class="form-label" for="industry">Industry</label>
                            <div class="dropdown" (click)="showDropdown = true">
                                <input type="text" class="form-control drop-btn" placeholder="Select Industry"
                                    id="industry" name="industry" [value]="orgnizationData.industry"
                                    (input)="searchIndusry($event)" required>
                                <img class="dropdown-form-image" src="../../../../assets/images/angle-down.png" alt="."
                                    srcset="" (click)="showDropdown = true"
                                    [ngClass]="{'rotate': showDropdown}">

                                <div class="dropdown-content" *ngIf="showDropdown" >
                                    <span (click)="dropdownValue(industry)" class="dropdown-text" *ngFor="let industry of filted_industry">{{industry}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bottom-border d-flex">
                        <div class="form-group" style="width: 46%;">
                            <label class="form-label" for="email">Billing Email</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter your email"
                                name="billingEmail" (change)="getInputVale($event)" required>
                        </div>
                        <div class="form-group" style="width: 50%;">
                            <label class="form-label" for="email">Support Email</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter your email"
                                name="supportEmail" (change)="getInputVale($event)" required>
                        </div>
                    </div>

                    <div class="bottom-border d-flex">
                        <div class="form-group" style="width: 46%;">
                            <label class="form-label" for="address">Organization Address</label>
                            <input type="text" class="form-control" id="address" placeholder="Address"
                                name="address" (change)="getInputVale($event)" required>
                        </div>
                        <div class="form-group" style="width: 50%;">
                            <label class="form-label" for="organizationName">Town / City</label>
                            <input type="text" class="form-control" placeholder="Town / City" id="organizationName"
                                name="city" (change)="getInputVale($event)" required>
                        </div>
                    </div>

                    <div>
                        <div class="d-flex bottom-border">
                            <div class="form-group" style="width: 46%;">
                                <label class="form-label" for="state">State</label>
                                <input type="text" class="form-control" placeholder="State" id="state" name="state"
                                    (change)="getInputVale($event)" required>
                            </div>
                            <div class="form-group" style="width: 24%;">
                                <label class="form-label" for="state">Country</label>
                                <app-country-dropdown class="right-border" [onlyCountry]="true" [top]="isScrollbarAtEnd" (selectOnlyCountry)="selectedCountries($event)"></app-country-dropdown>
                            </div>
                            <div class="form-group" style="width: 22%;">
                                <label class="form-label" for="postCode">Postcode</label>
                                <input type="number" class="form-control" placeholder="Postcode" id="postCode"
                                    name="postCode" (change)="getInputVale($event)" required>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <label class="form-label" for="taxNumber">Organization Tax Number</label>
                            <input type="text" class="form-control" id="taxNumber" placeholder="Tax Number" [max]="50"
                                name="taxNumber" (change)="getInputVale($event)" required>
                        </div>
                    </div>

                    <div class="field-container" style="height: 82px; ">
                        <div class="form-group" style="width: 46%;">
                            <label class="form-label" for="personName">Contact Person Name</label>
                            <input type="text" class="form-control" placeholder="Name" id="personName"
                                name="contactPersonName" (change)="getInputVale($event)" required>
                        </div>
                        <div class="form-group" style="width: 50%;">
                            <label class="form-label" for="rate">Contact Person Phone</label>
                            <div class="field-container">
                                <app-country-dropdown class="right-border" [top]="isScrollbarAtEnd" (selectCountry)="selectedCountryCode($event)" [top]="true"></app-country-dropdown>
                                <input type="number" class="form-control left-border" id="rate" placeholder="Phone"
                                    name="contactPersonNumber" (change)="getInputVale($event)" required
                                    style=" margin-bottom: 0!important;" >
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-group">
                            <label class="form-label" for="email">Contact Person Email</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter your email"
                                name="contactPersonEmail" (change)="getInputVale($event)" required>
                        </div>
                    </div>
                    <div class="toggle-container">Billing Module <label class="toggle-icon">
                            <input type="checkbox" [checked]="orgnizationData.billing" (click)="onChecked($event)">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </form>
            </div>
            <div class="service-container">
                <div>
                    <div class="service-header">
                        <h3 class="service-content">Services</h3>
                        <span class="service-content add-service-text" (click)="openAddService()">Add
                            Services</span>
                    </div>

                    <div class="service-list">
                        <div *ngFor="let service of confirmServices; let i = index" class="margin-bottom">
                            <div class="service-box" (mouseleave)="serviceDropdownClick[i]=false">
                                <div class="d-flex ">
                                    <div>
                                        <p class="m-0 service-heading">Service Name</p>
                                        <p class="m-0 service-content">{{service.name}}</p>
                                    </div>

                                    <button class="menu-button"><img src="assets/images/Menu Vertical.svg" alt="menu"
                                            (click)="serviceDropdownClick[i]=!serviceDropdownClick[i]"></button>
                                    <div class="service-dropdown-content" *ngIf="serviceDropdownClick[i]">
                                        <!-- <span (click)="serviceDropdownValue('edit',i)" class="dropdown-text">Edit</span> -->
                                        <span (click)="serviceDropdownValue('delete',i)"
                                            class="dropdown-text">Delete</span>
                                    </div>

                                </div>
                                <div class="d-flex">
                                    <div>
                                        <p class="m-0 service-heading">Service ID</p>
                                        <p class="m-0 service-content service-id">{{service.id}}</p>
                                    </div>
                                    <div>
                                        <p class="m-0 service-heading" style="text-align: end;">Date</p>
                                        <p class="m-0 service-content">{{service.createdAt | date}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal-popup *ngIf="deleteOrganization" titleValue="Delete" (closeClick)="deleteOrganization = false"
    (outsideClick)="deleteOrganization = false" [logout]="true">
    <p class="modal-text">Are you sure, you wanna <span style="font-weight: bold;">delete Organization "John
            Merchants"</span>?</p>
</app-modal-popup>

<app-modal-popup *ngIf="serviceDelete" (closeClick)="serviceDelete = false" (outsideClick)="serviceDelete = false"
    titleValue="Delete" (deleteButton)="deleteService(selectedIndexService)">
    <p class="modal-text">Are you sure, you wanna <span style="font-weight: bold;">delete service {{deleteServiceName}} for "{{orgnizationData.name?orgnizationData.name:''}}"</span>?</p>
</app-modal-popup>

<app-modal-popup *ngIf="addServicePopup" titleValue="Select a Service" (closeClick)="addServicePopup = false"
    [addPopup]="true" (outsideClick)="addServicePopup = false" (confirmButton)="confirmService(selectedService)">
    <div>
        <form>
            <div class="form-group">
                <label class="form-label" for="serviceName">Service Type</label>
                <div class="dropdown">
                    <input type="text" class="form-control drop-btn" placeholder="Select type" id="industry"
                        name="industry" [value]="selectName" (input)="searchService($event)" (click)="showDropdownType = !showDropdownType" required>
                    <img class="dropdown-form-image" src="../../../../assets/images/angle-down.png" alt="." srcset=""
                        (click)="showDropdownType = !showDropdownType" [ngClass]="{'rotate': showDropdownType}">

                    <div class="dropdown-content" *ngIf="showDropdownType" (mouseleave)="showDropdownType = false">
                        <span (click)="dropdownTypeValue(service)" class="dropdown-text"
                            *ngFor="let service of services_List;let i=index">{{service.name}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="serviceId">Service ID</label>
                <input type="text" class="form-control" placeholder="665465465747" id="serviceId" [value]="selectType"
                    readonly required>
            </div>
        </form>
    </div>

</app-modal-popup>

<app-modal-popup *ngIf="editServicePopup" titleValue="Edit a Service" (closeClick)="editServicePopup = false"
    [addPopup]="true" (outsideClick)="editServicePopup = false" (confirmButton)="confirmService(selectedService)">
    <div>
        <form>
            <div class="form-group">
                <label class="form-label" for="serviceName">Service Type</label>
                <div class="dropdown">
                    <input type="text" class="form-control drop-btn" placeholder="Select type" id="industry"
                        name="industry" [value]="selectName" (click)="showDropdownType = !showDropdownType" required>
                    <img class="dropdown-form-image" src="../../../../assets/images/angle-down.png" alt="." srcset=""
                        (click)="showDropdownType = !showDropdownType" [ngClass]="{'rotate': showDropdownType}">

                    <div class="dropdown-content" *ngIf="showDropdownType" (mouseleave)="showDropdownType = false">
                        <span (click)="dropdownTypeValue(service)" class="dropdown-text"
                            *ngFor="let service of serviceList;let i=index">{{service.name}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="serviceId">Service ID</label>
                <input type="text" class="form-control" placeholder="665465465747" id="serviceId" [value]="selectType"
                    readonly required>
            </div>
        </form>
    </div>
</app-modal-popup>

<app-modal-popup *ngIf="enableBillingModule" (closeClick)="enableBillingModule = false" [addPopup]="true" (confirmButton)="onEnableBillingModule()"
    (outsideClick)="enableBillingModule = false" titleValue="Billing Module">
    <p class="modal-text">Are you sure, you wanna <span style="font-weight: bold;">{{orgnizationData.billing?'disable':'enable'}} Billing
            Module</span>?</p>
</app-modal-popup>