import { Injectable } from '@angular/core';
import { NetworkService } from '../network/network.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingCentralService {

  /**
   * Property to hold service url
   */
  url = (environment as { apiRoute: string }).apiRoute + "/aggregation/services";

  constructor(private network: NetworkService) { }

  /**
   * Method to get billing central data
   */
  getBillingCentral(page: number, limit: number, search = '') {
    return this.network.get(`/payment/invoice/list?limit=${limit}&page=${page}&invoiceNumber=${search}`);
  }

  /**
   * Method to get billing central data
   */
  getInvoice(id:string|undefined) {
    return this.network.get(`/payment/order-mapping/list?_id=${id}`);
  }
}



