import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-success',
  templateUrl: './reset-success.component.html',
  styleUrls: ['./reset-success.component.scss']
})
export class ResetSuccessComponent implements OnInit{

  role='SUPERADMIN'

  constructor(private route: ActivatedRoute) { 
  }

  ngOnInit(): void {
    this.role = String(this.route.snapshot.paramMap.get('role'));
  }

}
