<div class="main-container">
    <div class="sidebar-container">
        <app-sidebar></app-sidebar>
    </div>

    <div class="table-container">
        <div class="heading-container">
            <div>
                <h2 class="module-heading">Personal Details</h2>
                <span class="module-sub-heading">View or update your <span style="font-weight: bold;">personal
                        details</span> here.</span>
            </div>
            <div class="button-container">
                <button class="black-button button-width" (click)="enableEdit()" *ngIf="editEnabled">Edit</button>
                <button class="black-button button-width" (click)="editProfile(adminProfile)" *ngIf="!editEnabled">Save</button>
                <button class="reset-button reset-button-width" (click)="updatePassword=true">Update Password</button>
            </div>
        </div>
        <div class="main-detail-container">
            <div class="form-container" style="height: 76vh;overflow: auto;">
                <form>
                    <div class="bottom-border d-flex">
                        <div class="form-group" style="width: 46%;">
                            <label class="form-label" for="organizationName">First Name</label>
                            <input type="text" class="form-control" placeholder="Name" id="firstName" name="firstName" (change)="getFirstName($event)" [value]="adminProfile.firstName" required [disabled]="editEnabled">
                        </div>
                        <div class="form-group" style="width: 50%;">
                            <label class="form-label" for="organizationName">Last Name</label>
                            <input type="text" class="form-control" placeholder="Name" id="lastName" name="lastName" (change)="getLastName($event)" [value]="adminProfile.lastName" required [disabled]="editEnabled">
                        </div>
                    </div>
                    <div class="bottom-border">
                        <div class="form-group">
                            <label class="form-label" for="email">Email Address</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter your email" name="email" (change)="getEmail($event)" [value]="adminProfile.email" required [disabled]="editEnabled">
                        </div>
                    </div>

                    <div class="field-container bottom-border" style="height: 82px; ">
                        <div class="form-group" style="width: 46%;">
                            <label class="form-label" for="rate">Phone</label>
                            <div class="field-container">
                                <app-country-dropdown class="right-border" (selectCountry)="selectedCountryCode($event)" [selectedText]="adminProfile.phone?(adminProfile.phone.countryCode):'+1'" [editEnable]="editEnabled"></app-country-dropdown>
                                <input type="number" class="form-control left-border" id="rate" placeholder="Phone" name="phone" (change)="getPhone($event)" [value]="adminProfile.phone ? adminProfile.phone.phoneNumber:''" [disabled]="editEnabled"
                                    required >
                            </div>
                        </div>
                        <div class="form-group" style="width: 50%;">
                            <label class="form-label" for="personName">Date of Birth</label>
                            <input type="date" class="form-control" placeholder="Name" id="dateofBirth" name="dob" (change)="getDob($event)" [value]="(adminProfile.dob)?formatDate(adminProfile.dob):''" required [disabled]="editEnabled">
                        </div>
                    </div>

                    <div class="">
                        <div class="form-group">
                            <label class="form-label" for="address">Street Address</label>
                            <input type="email" class="form-control" id="address" placeholder="Address" name="street" (change)="getStreet($event)" [value]="adminProfile.address?adminProfile.address.street:''"
                                required [disabled]="editEnabled">
                        </div>
                        <div class="d-flex">
                            <div class="form-group" style="width: 46%;">
                                <label class="form-label" for="organizationName">Town / City</label>
                                <input type="text" class="form-control" placeholder="Town / City" id="organizationName" name="city" (change)="getCity($event)" [value]="adminProfile.address?adminProfile.address.city:''"
                                    required [disabled]="editEnabled"> 
                            </div>
                            <div class="form-group" style="width: 24%;">
                                <label class="form-label" for="state">State</label>
                                <input type="text" class="form-control" placeholder="State" id="state" name="state" (change)="getState($event)" [value]="adminProfile.address?adminProfile.address.state:''" required [disabled]="editEnabled">
                            </div>
                            <div class="form-group" style="width: 20%;">
                                <label class="form-label" for="postCode">Postcode</label>
                                <input type="number" class="form-control" placeholder="Postcode" id="postCode" name="zipCode" (change)="getZipCode($event)" [value]="adminProfile.address?adminProfile.address.zipCode:''" required [disabled]="editEnabled">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-modal-popup *ngIf="updatePassword" titleValue="Update Password" (closeClick)="updatePassword = false" [addPopup]="true"
    (outsideClick)="updatePassword = false" (confirmButton)="UpdatePasswordData(password)">
    <div style="text-align: center;padding-bottom: 30px;color:#919191;">Please enter your new password</div>
    <div>
        <form>
            <div class="form-group">
                <label class="form-label" for="serviceName">Current Password</label>
                <input [type]="togglePassword ? 'password' : 'text'" class="form-control" placeholder="Current Password" id="password" name="oldPassword" (change)="getOldPassword($event)" required>
                <i *ngIf="togglePassword" class="fa fa-eye-slash" style="position: absolute; right: 32px; top: 33%; transform: translateY(-50%); cursor: pointer;color:#919191;" (click)="this.togglePassword=!this.togglePassword;"></i>
                <i *ngIf="!togglePassword" class="fa fa-eye" style="position: absolute; right: 32px; top: 33%; transform: translateY(-50%); cursor: pointer; color:#919191;" (click)="this.togglePassword=!this.togglePassword;"></i>
            </div>
            <div class="form-group">
                <label class="form-label" for="serviceId">New Password</label>
                <input [type]="newPassword ? 'password' : 'text'" class="form-control" placeholder="New Password" name="newPassword" (change)="getNewPassword($event)" required>
                <i *ngIf="newPassword" class="fa fa-eye-slash" style="position: absolute; right: 32px; top: 51%; transform: translateY(-50%); cursor: pointer;color:#919191;" (click)="this.newPassword=!this.newPassword;"></i>
                <i *ngIf="!newPassword" class="fa fa-eye" style="position: absolute; right: 32px; top: 51%; transform: translateY(-50%); cursor: pointer; color:#919191;" (click)="this.newPassword=!this.newPassword;"></i>
            </div>
            <div class="form-group">
                <label class="form-label" for="serviceId">Confirm Password</label>
                <input [type]="confirmPassword ? 'password' : 'text'" class="form-control" placeholder="Confirm Password" name="confirmPassword" (change)="getConfirmPassword($event)" required>
                <i *ngIf="confirmPassword" class="fa fa-eye-slash" style="position: absolute; right: 32px; top: 69%; transform: translateY(-50%); cursor: pointer;color:#919191;" (click)="this.confirmPassword=!this.confirmPassword;"></i>
                <i *ngIf="!confirmPassword" class="fa fa-eye" style="position: absolute; right: 32px; top: 69%; transform: translateY(-50%); cursor: pointer; color:#919191;" (click)="this.confirmPassword=!this.confirmPassword;"></i>
            </div>
        </form>
    </div>

</app-modal-popup>