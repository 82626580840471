<div class="main-container">
    <div class="sidebar-container">
        <app-sidebar></app-sidebar>
    </div>

    <div class="table-container">
        <div class="heading-container">
            <div>
                <h2 class="module-heading">Invite Team Users</h2>
                <span class="module-sub-heading">Invite Organization team users here via email or sharing link</span>
            </div>
        </div>
        <div class="main-detail-container">
            <div class="form-container">
                <form>
                    <div class="bottom-border">
                        <div class="form-group">
                            <label class="form-label" for="email">Email</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter your email" required>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-group" style="width: 46%;">
                            <label class="form-label" for="username">Username</label>
                            <input type="text" class="form-control" placeholder="Name" id="username" required>
                        </div>
                        <div class="form-group" style="width: 50%;">
                            <label class="form-label" for="password">Password</label>
                            <input [type]="togglePassword ? 'password' : 'text'" class="form-control"
                                placeholder="Password" id="password" required>
                            <i *ngIf="togglePassword" class="fa fa-eye-slash" style="float: right;margin-right: 4%;margin-top: -7%;transform: translateY(-50%); cursor: pointer;color:#919191;"
                                (click)="this.togglePassword=!this.togglePassword;"></i>
                            <i *ngIf="!togglePassword" class="fa fa-eye" style="float: right;margin-right: 4%;margin-top: -7%;transform: translateY(-50%); cursor: pointer; color:#919191;"
                                (click)="this.togglePassword=!this.togglePassword;"></i>
                        </div>
                    </div>

                    <div class="d-flex" style="align-items: center;">
                        <a routerLink="/team-users" class="add-user">Add More User</a>
                        <button class="black-button-value" style="width: 15%;">Confirm</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>