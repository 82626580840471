import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  /**
   * Property to hold service url
   */
  url = '';
  private isLogged = false;

  constructor(private route: Router, private http: HttpClient) {
    this.url = environment.apiRoute;
  }

  /**
   * Login service
   */
  public login(data: { email: string, password: string },keepLoggedIn: boolean) {
    this.isLogged = true;
    if (keepLoggedIn) {
      localStorage.setItem('isLoggedIn', 'true');
    }
    return this.http.post(
      `${this.url}/auth/login/super-admin`, data)
  }

  /**
   * Forgot password service
   */
  public forgotPassword(email: string) {
    const url = `${this.url}/auth/forgot-password/super-admin`;
    return this.http.post(url, { email });
  }

  /**
   * Reset password service
   */
  public resetPassword(data: object) {
    return this.http.post(
      `${this.url}/auth/forgot-password/reset-password`, data
    )
  }

  /**
   * Check if user is logged
   */
  public isLoggedIn() {
    return this.getToken() !== null;
  }

  /**
   * Get token
   */
  public getToken(): string | null {
    return localStorage.getItem('@$T0ken');
  }

  /**
   * Method to get new token
   */
  public getNewToken() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
    });
    return this.http.get(
      `${this.url}/auth/refresh/super-admin`,{ headers }
    )
  }

  /**
   * Set token
   */
  public setToken(data: string): void {
    localStorage.setItem('@$T0ken', data);
  }

  /**
   * Logout 
   */
  public logout() {
    localStorage.clear();


    // Reset the isLoggedIn flag
    this.isLogged = false;
  }

  getIsLoggedIn(): boolean {
    // Check if the user is logged in based on the local storage or cookie value
    return this.isLogged || localStorage.getItem('isLoggedIn') === 'true';
  }
}
