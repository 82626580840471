import { Injectable } from '@angular/core';
import { NetworkService } from '../network/network.service';

@Injectable({
  providedIn: 'root'
})
export class AdminProfileService {

  /**
   * Property to handle profile data 
   */
  adminProfile:Profile={
    firstName:'',
    lastName:'',
    photo:'',
    role:{
      roleId:'',
      roleType:''
    }
  }

  /**
   * Property to hold service url
   */
  url = "/auth/super-admin";

  constructor(private network: NetworkService) { }

  /**
   * Method to get billing central data
   */
  getAdminProfile() {
    return this.network.get(`${this.url}/my-profile`);
  }

  /**
   * Method to get billing central data
   */
  updateAdminProfile(data:object) {
    return this.network.post(`${this.url}/edit-profile`,data);
  }

  /**
   * Method to get billing central data
   */
  updatePassword(data:object) {
    return this.network.post(`/auth/change-password/admin`,data);
  }
}

interface Profile{
  firstName:string,
  lastName:string,
  photo:string,
  role:{
    roleId:string,
    roleType:string
  }
}
