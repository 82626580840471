<div class="country-dropdown" [ngClass]="{'onlyCountry': onlyCountry}" (click)="showList($event)">
    <div class="d-flex">
            <p class="text-center" style="margin: 8px 5px;" [ngClass]="{'onlyCountryText': (selectedName==='Select Country' && onlyCountry)}">{{onlyCountry?selectedName:selectedText}}</p>
            <i *ngIf="!editEnable" class="fa fa-solid  fa-lg"
                        [ngClass]="{'fa-caret-down': open!==true, 'fa-caret-up': open===true}" style="margin-top:10px"></i>
        
    </div>
    <ul *ngIf="open && !editEnable" class="countries" [ngClass]="{'upward': top}">
        <li class="country">
            <input #input (click)="stopPropagation($event)" (keyup)="search($event.target)" class="custom-input mb-3"
                placeholder="Search" type="text" style="height: 30px;width: -webkit-fill-available;border: 1px solid lightgray;border-radius:5px;">
        </li>
        <li (click)="selectItem($event,country.dial_code, country.flag, country.name)" *ngFor="let country of countries"
            class="country">
            <img [src]="country.flag" alt="" width="25px" height="25px" style="border-radius: 30%;">
            <span style="padding-left:10px;" *ngIf="!onlyCountry">{{country.dial_code}} ({{country.name}})</span>
            <span style="padding-left:10px;" *ngIf="onlyCountry">{{country.name}}</span>
        </li>
    </ul>
</div>