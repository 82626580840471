<section class="background-overlay">
  <div class="container">
      <div class="login-box">
          <div class="logo-container">
              <img src="assets/images/Layer_1.svg" alt="logo">
              <span class="logo-text">PHOTOWONDER</span>
          </div>
          <h2 class="heading">Reset Password</h2>
          <p class="sub-heading">No worries, we'll send you reset instructions.</p>
          <form [formGroup]="forgotPasswordForm" >
              <div class="form-group" style="position: relative;">
                  <label class="form-label" for="email">New Password</label>
                  <input [type]="togglePassword ? 'password' : 'text'" class="form-control" id="newPassword" placeholder="Password" formControlName="password" required>
                  <i *ngIf="togglePassword" class="fa fa-eye-slash" style="position: absolute; right: 4%; top: 68%; transform: translateY(-50%); cursor: pointer;color:#919191;" (click)="this.togglePassword=!this.togglePassword;"></i>
                <i *ngIf="!togglePassword" class="fa fa-eye" style="position: absolute; right: 4%; top: 68%; transform: translateY(-50%); cursor: pointer; color:#919191;" (click)="this.togglePassword=!this.togglePassword;"></i>
              </div>
              <div class="form-group" style="position: relative;">
                <label class="form-label" for="email">Confirm Password</label>
                <input [type]="togglePassword2 ? 'password' : 'text'" class="form-control" id="confirmPassword" placeholder="Password" formControlName="confirmPassword" required>
                <i *ngIf="togglePassword2" class="fa fa-eye-slash" style="position: absolute; right: 4%; top: 67.5%; transform: translateY(-50%); cursor: pointer;color:#919191;" (click)="this.togglePassword2=!this.togglePassword2;"></i>
                <i *ngIf="!togglePassword2" class="fa fa-eye" style="position: absolute; right: 4%; top: 67.5%; transform: translateY(-50%); cursor: pointer; color:#919191;" (click)="this.togglePassword2=!this.togglePassword2;"></i>
            </div>
              <button type="submit" class="btn btn-primary" (click)="forgotPassword()">Confirm</button>
              <div class="forgot-password"><a routerLink="/login" class="pass-link">Back to login</a></div>
          </form>
      </div>
  </div>
</section>