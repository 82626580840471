import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableComponent } from './Shared/table/table.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrganizationManagementComponent } from './pages/organization-management/organization-management.component';
import { ModalPopupComponent } from './Shared/modal-popup/modal-popup.component';
import { DetailsComponent } from './pages/organization-management/details/details.component';
import { ServiceManagementComponent } from './pages/service-management/service-management.component';
import { DetailSidebarComponent } from './Shared/detail-sidebar/detail-sidebar.component';
import { BillingCentralComponent } from './pages/billing-central/billing-central.component';
import { PersonalDetailsComponent } from './pages/personal-details/personal-details.component';
import { TeamUsersComponent } from './pages/team-users/team-users.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { EditDetailsComponent } from './pages/organization-management/edit-details/edit-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderInterceptorInterceptor } from './interceptor/loader-interceptor.interceptor';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountryDropdownComponent } from './components/country-dropdown/country-dropdown.component';
import { LeadsComponent } from './pages/leads/leads.component';
import { ResetSuccessComponent } from './pages/reset-success/reset-success.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SidebarComponent,
    TableComponent,
    OrdersComponent,
    OrganizationManagementComponent,
    ModalPopupComponent,
    DetailsComponent,
    ServiceManagementComponent,
    DetailSidebarComponent,
    BillingCentralComponent,
    PersonalDetailsComponent,
    TeamUsersComponent,
    EditDetailsComponent,
    ResetPasswordComponent,
    CountryDropdownComponent,
    LeadsComponent,
    ResetSuccessComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    NoopAnimationsModule,
    BrowserAnimationsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
