import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-detail-sidebar',
  templateUrl: './detail-sidebar.component.html',
  styleUrls: ['./detail-sidebar.component.scss']
})
export class DetailSidebarComponent{

  /**
   * Property to open detail sidebar popup
   */
  @Input() isOpen = false;

  /**
   * Property to get data
   */
  @Input() data: InvoiceData={} as InvoiceData;

  /**
   * Property to close detail sidebar popup
   */
  @Output() closeClick = new EventEmitter();

  /**
   * Method to toggle detail sidebar popup
   */
  toggleSidebar() {
    this.closeClick.emit(false);
  }

  calculateDateDifference(startDate: string, endDate: string): number {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDifference = end.getTime() - start.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }
}


interface InvoiceData {
  _id: string;
  _organization: {
    createdBy: {
      _id: string;
      role: string;
    };
    _id: string;
    name: string;
    industry: string;
    billingEmail: string;
    supportEmail: string;
    address: {
      street: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      geo: [number, number];
      _id: string;
    };
    taxNumber: string;
    billing: boolean;
    email: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    uniqueId: string;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonNumber: {
      countryCode: string;
    };
  };
  amount: {
    totalAmount: number;
    units: string;
  };
  invoiceNumber: string;
  paymentStatus: string;
  timePeriod: {
    startDate: string;
    endDate: string;
  };
  fileCount: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
  orders: Order[];
  invoiceLink: string;
}

 interface Order {
  _id: string;
  name: string;
  orderNumber: string;
  _organization: string;
  _service: {
    _id: string;
    name: string;
    rate: {
      amount: number;
      units: string;
    };
  };
  orderDate: string;
  notes: string;
  status: string;
  createdBy: {
    _id: string | null;
    role: string;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
  billableImages: number | string;
}