<div class="main pb-3 pt-3 px-4">
    <table class="table" >
        <thead class="bb-table-head">
            <tr class="bb-table-row">
                <th *ngFor="let header of tableData.thead">{{header.text}}</th>
                <th scope="col" *ngIf="showActions" class="my-2">Actions</th>
            </tr>
        </thead>
        <tbody class="bb-table-body" *ngIf="!isEmpty(tableData.tbody)">
            <tr *ngFor="let body of tableData.tbody | paginate: { itemsPerPage: 10, currentPage: currentPage, totalItems: tableDataSize}; let i = index;" class="table-body-row">
                <td *ngFor="let header of tableData.thead">
                    <div *ngIf="header.type !== 'toggle' && header.type !== 'status' && header.id !== 'image' && header.id!=='rating'
                  && header.id!=='icon' && header.id!=='url' && header.id!=='photo'">
                        <div *ngIf="header.shouldTrigger"> <a href="JavaScript:void(0)" (click)="triggerDetailModal(i)"
                                class="name"> {{ body[header.id]}} </a> </div>
                        <div *ngIf="!header.shouldTrigger">{{ body[header.id] }}</div>
                    </div>
                    <div *ngIf="header.type === 'status' && !dropdownButton">
                        <button type="button"
                            [ngClass]="{'status-complete': body[header.id] === 'Completed' ||body[header.id] === 'PAID' || body[header.id] === 'ACTIVE' || body[header.id] === 'Paid', 'status-cancelled': body[header.id] === 'Cancelled' || body[header.id] === 'Pending' || body[header.id] === 'PENDING' || body[header.id] === 'FAILURE'|| body[header.id] === 'INACTIVE'}" >{{body[header.id]}}</button>
                    </div>

                    <div *ngIf="header.type === 'status' && dropdownButton" style="position: relative;">
                        <button type="button" [ngClass]="{'status-complete': body[header.id] === 'Completed' ||body[header.id] === 'PAID'||body[header.id] === 'APPROVED' || body[header.id] === 'ACTIVE' || body[header.id] === 'Paid', 'status-cancelled': body[header.id] === 'Cancelled' || body[header.id] === 'Pending' || body[header.id] === 'REJECTED' || body[header.id] === 'PENDING' || body[header.id] === 'INACTIVE'}" (click)="toggleDropdown($event,i)">{{body[header.id]}}</button>
                        <img class="dropdown-form-image" src="../../../../assets/images/angle-down.png" alt="."
                                    srcset="" (click)="showDropdown[i] = !showDropdown[i]"
                                    [ngClass]="{'rotate': showDropdown[i]}">
                        <div class="dropdown status-dropdown" *ngIf="showDropdown[i]">
                            <div class="dropdown-items">
                                <button (click)="setStatus('Accepted')" style="background-color:white;padding-bottom: 7px;color:#69717c;cursor: pointer;">ACCEPTED</button>
                            </div>
                            <div >
                                <button (click)="setStatus('Rejected')" style="background-color: white; color:#69717c;cursor: pointer;">REJECTED</button>
                            </div>
                            
                          </div>
                    </div>
                    <div *ngIf="header.id === 'image'" class="link-container">
                        <a [href]="body[header.id]" target="_blank">
                            <img [src]="body[header.id]" height="25" width="25" alt="image">

                        </a>
                    </div>
                    <div *ngIf="header.id === 'icon'" class="link-container">
                        <a [href]="body[header.id]" target="_blank">
                            <img [src]="body[header.id]" height="25" width="25" alt="image">

                        </a>
                    </div>
                    <div *ngIf="header.id === 'url'" class="link-container">
                        <a [href]="body[header.id]" target="_blank">
                            <img [src]="body[header.id]" height="25" width="25" alt="image">
                        </a>
                    </div>

                    <div *ngIf="header.id === 'photo'" class="link-container">
                        <a [href]="body[header.id]" target="_blank">
                            <img [src]="body[header.id]" height="25" width="25" alt="image">
                        </a>
                    </div>
                    <div *ngIf="header.id === 'rating' && showRatings" class="link-container">
                    </div>
                </td>
                <td *ngIf="showArrowButton">
                    <span>
                        <button (click)="publish(body,i)">
                            <img src="assets/images/arrow.svg" alt="">
                        </button>
                    </span>
                </td>
                <td *ngIf="showActions">
                    <span *ngFor="let action of actions">
                        <button (click)="actionType(action.type, i)" class="actnbtn"
                            [ngClass]="{'bg-black': action.type === 'edit' || action.type === 'check' || action.type === 'event', 'bg-red': action.type === 'delete' || action.type === 'uncheck'}">
                            <span style="cursor: pointer;">{{action.src}}</span>
                        </button>
                    </span>
                </td>
            </tr>
        </tbody>
        <tr *ngIf="showPagination && !isEmpty(tableData.tbody)" >
            <td colspan="15" class="pagination-right">
                <div class="row row-pagination">
                    <div class="col-lg-8"></div>
                    <div class="col-lg-2">
                <p class="pageno-page">{{startPageNumber}} - {{endPageNumber}} of {{tableDataSize}}</p>
            </div>
            <div class="col-lg-2 arrow-btn">
                <pagination-controls (pageChange)="changePageNumber($event)" [maxSize]="0" class="hide-page-numbers" previousLabel=""
                nextLabel="" ></pagination-controls> 
                </div>
                </div>
            </td>
        </tr>

    </table>
    <div class='not-found' *ngIf="isEmpty(tableData.tbody)">No Results Found</div>
</div>