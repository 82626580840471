<div class="main-container">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>

  <div class="table-container">
    <div class="heading-container">
      <h2 class="module-heading">Billing Central > Payments </h2>
      <div class="search-container">
        <input type="text" class="search-bar" placeholder="Search by Invoice ID" (keydown.enter)="onSearch($event)">
        <span class="search-icon"></span>
      </div>
    </div>
    <div>
      <app-table 
      [tableData]="billingTableData" 
      [showActions]="false" 
      [showPagination]="true"
      [tableDataSize]="tableDataSize"
      [pageSize]="pageLimit"
      [pageNumber]="pageNumber"
      (fetchAllWithPagination)="fetchAllWithPagination($event)"
      (actionButtonHandler)="onActionButtonHandler($event)"
        ></app-table>
    </div>
  </div>
</div>

<div *ngIf="openDetailSidebar">
  <app-detail-sidebar [data]="invoiceDetails" [isOpen]="openDetailSidebar" (closeClick)="openDetailSidebar = false"></app-detail-sidebar>
</div>
