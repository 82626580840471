import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges{


  /**
   * Property to display current page of table
   */
  public currentPage = 1;

  /**
   * Property to display current page of table
   */
  @Input() public pageNumber = 1;

  /**
   * Property to display dropdown button
   */
  @Input() public dropdownButton = false;

  /**
   * Property to display checkboxes in table
   */
  @Input() public showCheckBox = false;

  /**
   * Property to display pagination in table
   */
  @Input() public showPagination = false;

  /**
   * Property to dsiplay Action icons in table
   */
  @Input() public showActions = true;

  /**
  * Property to dsiplay Publish icons in table
  */
  @Input() public showArrowButton = false;
  /**
   * Property to dsiplay Ratings in table
   */
  @Input() public showRatings = false;


  /**
  * Property to apply search filter in table
  */
  @Input() public searchText = '';
  /**
   * Property to decide which action buttons to display
   */
  @Input() public actions = [
    // { type: 'check', src: 'fa fa-check' },
    // { type: 'uncheck', src: 'fa fa-close' },
    { type: 'edit', src: 'Edit' },
    { type: 'delete', src: 'Delete' },
    // { type: 'event', src: 'fa fa-calendar' },
  ];

  /**
   * Property to handle event when action buttons are clicked
   */
  @Output() actionButtonHandler = new EventEmitter<{actionType: string; index: number}>();

  /**
  * Property to handle event when action buttons are clicked
  */
  @Output() publishHandler = new EventEmitter<object>();

  /**
   * Property to handle checkbox event in the table
   */
  @Output() checkboxClickHandler = new EventEmitter<Array<string>>();


  /**
   * Property to handle toggle event in the table
   */
  @Output() toggleButtonHandler = new EventEmitter<object>();

  /**
  * Property to handle toggle event in the table
  */
  @Output() fetchAllWithPagination = new EventEmitter<number>();

   /**
  * Property to handle toggle event in the table
  */
   @Output() dropdownStatus = new EventEmitter<string>();

  /**
   * Property to hold table Data size
   */
  @Input() public tableDataSize=0;


  /**
    * Property to handle table data
    */
  @Input() tableData: {
    thead:HeaderData[],
    tbody: BodyData[] | Data[]
  } =
    {
      thead: [
        {
          id: 'name',
          text: 'Name',
          isSortable: true,
          type: 'inputText'
        },
        {
          id: 'email',
          text: 'Email',
          isSortable: true,
          type: 'inputText'
        },
        {
          id: 'phoneNumber',
          text: 'Phone Number',
          isSortable: true,
          type: 'inputText'
        },
        {
          id: 'status',
          text: 'status',
          isSortable: true,
          type: 'status'
        },
        {
          id: 'image',
          text: 'Banner',
          isSortable: false,
          type: 'link'
        },
        // {
        //   id: 'rating',
        //   text: 'Rating',
        //   isSortable: false,
        //   type: 'rating'
        // }
      ],
      tbody: [{ name: 'yaskaran', email: 'yaskaran', phoneNumber: 'yaskaran', status: "Completed", image: 'yaskaran' },
      { name: 'yaskaran', email: 'yaskaran', phoneNumber: 'yaskaran', status: "Cancelled", image: 'yaskaran' }]
    };

  /**
   * Method to store selected elements index
   */
  public selectedElements: string[] = [];


  /**
     * Property to hold page size of the table
     */
  @Input() public pageSize = 10;
  /**
   * Property to hold start page number of the table
   */
  public startPageNumber = 1;
  /**
  * Property to hold end page number of the table
  */
  public endPageNumber!: number;

  showDropdown:[boolean] = [false];
  selectedStatus='';

  toggleDropdown(event: Event,index:number) {
    // Prevent the click event from propagating to the outer table row, if applicable.
    event.stopPropagation();
    this.showDropdown[index] = !this.showDropdown[index];
  }

  setStatus(status: string) {
    this.selectedStatus = status;
    this.dropdownStatus.emit(status);
    this.showDropdown = [false];
  }

  ngOnChanges() {
    this.endPageSize();
    this.currentPage=this.pageNumber;
    this.startPageNumber=this.pageNumber;
  }

  isEmpty(array:object[]):boolean{
    if (array.length === 1 && typeof array[0] === 'object' && Object.keys(array[0]).length === 0) {
      return true
  } else {
     return false
  }
  }

  /**
   * Method to hold end page number of the table
   */
  private endPageSize() {
    if (this.tableDataSize < this.pageSize) {
      this.endPageNumber = this.tableDataSize;
    } else {
      this.endPageNumber = this.pageSize;
    }
  }


   /**
   * Method to change page number of the table
   */
 public changePageNumber(event:number){
  this.fetchAllWithPagination.emit(event);
   if(event > this.currentPage){
     this.startPageNumber = this.startPageNumber+ this.pageSize;
     this.endPageNumber = this.endPageNumber+ this.pageSize;
     if(this.tableDataSize < this.endPageNumber ){
       this.endPageNumber = this.tableDataSize;
     } 
   } else if(event < this.currentPage){
     const difference = this.endPageNumber - this.startPageNumber;
     this.startPageNumber = this.startPageNumber - this.pageSize;
     if(this.endPageNumber === this.tableDataSize){
       this.endPageNumber = this.endPageNumber - difference -1;
       
     } else {
       this.endPageNumber = this.endPageNumber - this.pageSize;
     }
   }
     this.currentPage=event;
  }

  /**
   * Propety to trigger actions on aciton button
   */
  public actionType(actionType: string, index: number) {
    this.actionButtonHandler.emit({ actionType, index });
  }

  /**
   * Propety to trigger publish button
   */
  public publish(body: object, index: number) {
    this.publishHandler.emit({ body });
    this.actionButtonHandler.emit({ actionType: 'next-edit', index });
  }
 

  /**
   * Propety to trigger detail modal
   */
  public triggerDetailModal(index: number) {
    this.actionButtonHandler.emit({ actionType: 'detail-modal', index });
  }

  

}

interface Data {
  createdBy: CreatedBy;
  _id: string;
  name: string;
  industry: string;
  billingEmail: string;
  supportEmail: string;
  address: Address;
  taxNumber: string;
  billing: boolean;
  email: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  contactPerson: ContactPerson[];
  totalUsers: number;
  totalServices: number;
  url?: string;
  phone?: Phone;
  // eslint-disable-next-line
  [key: string]: any;
}

interface ContactPerson {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface CreatedBy {
  _id: string;
  role: string;
}

interface Phone {
  countryCode: string;
  phoneNumber: string;
  _id: string;
}

interface Address {
  street: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  geo: number[];
  _id: string;
}

interface HeaderData{
  id: string; 
  text: string; 
  isSortable: boolean; 
  type: string; 
  shouldTrigger?: boolean
}

interface BodyData{
  [key:string]:string | null | undefined
}