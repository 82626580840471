import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdminProfileService } from 'src/app/services/admin-profile/admin-profile.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent {

  /**
   * Property to handle profile data 
   */
  adminProfile: Data = {
    firstName: '',
    lastName: '',
    email: '',
    phone: {
      countryCode: '',
      phoneNumber:''
    },
    address: {
      street: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      geo: [0, 0]
    },
    dob:''
  }

  /**
   * Property to hold password data
   */
  password:PasswordData = {
    oldPassword: '',
    newPassword: '',
    confirmPassword:''
  }


  /**
   * Property to display delete organization popup
   */
  deleteOrganization = false;

  /**
   * Property to display update password popup
   */
  updatePassword=false;

  /**
   * Property to hold toggle password
   */
  togglePassword=false;
  newPassword=false;
  confirmPassword=false;

  /**
   * Method to display delete organization popup
   */
  onDeleteOrganization() {
    this.deleteOrganization = true
  }

  constructor(private router: Router, private profile: AdminProfileService) {
    this.getProfile()
  }

  /**
   * Method to get input value
   */
  getFirstName(event: Event) {
    this.adminProfile.firstName = (event.target as HTMLInputElement).value
  }

  getLastName(event: Event) {
    this.adminProfile.lastName = (event.target as HTMLInputElement).value
  }

  getEmail(event: Event) {
    this.adminProfile.email = (event.target as HTMLInputElement).value
  }

  phoneNumber='';
  state='';
  city='';
  street='';
  zipCode='';
  countryCode='';
  getPhone(event: Event) {
      this.phoneNumber = (event.target as HTMLInputElement).value
  }

  getDob(event: Event) {
    this.adminProfile.dob = (event.target as HTMLInputElement).value
  }

  getStreet(event: Event) {
      this.street = (event.target as HTMLInputElement).value
    
  }

  getCity(event: Event) {
      this.city= (event.target as HTMLInputElement).value
  }

  /**
   * Method to select country code
   */
      selectedCountryCode(countryCode: string) {
          this.countryCode = countryCode;
      }

  getState(event: Event) {
      this.state = (event.target as HTMLInputElement).value
  }

  getZipCode(event: Event) {
      this.zipCode = (event.target as HTMLInputElement).value
  }

  getOldPassword(event: Event) {
    this.password.oldPassword = (event.target as HTMLInputElement).value
  }

  getNewPassword(event: Event) {
    this.password.newPassword = (event.target as HTMLInputElement).value
  }

  getConfirmPassword(event: Event) {
    this.password.confirmPassword = (event.target as HTMLInputElement).value
  }

  formatDate(dateString: string): string {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      return date.toISOString().substring(0, 10);
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }

  UpdatePasswordData(data:PasswordData){
    const pattern=/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&*!~_+=.-])[A-Za-z\d@#$%^&*!~_+=.-]{8,}$/
    if(this.password.oldPassword==''){
      customAlert("Please enter current password",'warning')
    }else if((this.password.oldPassword.match(new RegExp(pattern))==null) ){
      messageAlert("validPassword",'warning')
    }else if(this.password.newPassword==''){
      customAlert("Please enter new password",'warning')
    }else if((this.password.newPassword.match(new RegExp(pattern))==null) ){
      messageAlert("validPassword",'warning')
    }else if(this.password.confirmPassword==''){
      customAlert("Please enter confirm password",'warning')
    }else if((this.password.confirmPassword.match(new RegExp(pattern))==null) ){
      messageAlert("validPassword",'warning')
    }else if(this.password.newPassword!==this.password.confirmPassword){
      customAlert("New and Confirm passwords do not match",'warning')
    }else{
      this.profile.updatePassword(data).subscribe({
        next: (data: ApiResponse) => {
          if(data.success){
            messageAlert('updatePassword','success')
          this.updatePassword=false;
          this.getProfile()
          }else{
            customAlert(data.message,'warning')
          }
          
        }, error: (err) => {
          customAlert(err.error.message,'warning')
        }
      })
    }
  }

  editEnabled = true
  enableEdit() {
    this.editEnabled = false
  }

  getProfile() {
    this.profile.getAdminProfile().subscribe({
      next: (data: ApiResponse) => {
        this.adminProfile = data.data;
      },
      error: () => {
        customAlert("invalid",'warning');
      }
    })
  }

  editProfile(data:Data){
    const body:Data={
      firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    }

    if(data.dob!=='' && data.dob!==undefined){
      body['dob']=new Date(data.dob).toISOString()
    }

    if(this.phoneNumber && this.phoneNumber!==''){
      const phone= {
        countryCode:this.countryCode?this.countryCode:'+1',
        phoneNumber:this.phoneNumber?this.phoneNumber:''
      }
      body['phone']=phone;
    }

    if(this.street && this.street!=='' && this.city && this.city!=='' && this.state && this.state!=='' && this.zipCode && this.zipCode!==''){
      const address= {
        street: this.street?this.street:'',
        city: this.city?this.city:'',
        state: this.state?this.state:'',
        country: 'India',
        zipCode: this.zipCode?this.zipCode:'',
        geo: [0, 0]
      }

      body['address']=address;
    }

    this.profile.updateAdminProfile(body).subscribe({
      next: () => {
        messageAlert('updateProfile','success')
        this.profile.adminProfile.firstName=data.firstName;
        this.profile.adminProfile.lastName=data.lastName;
        this.getProfile();
        this.editEnabled = true;
      },
      error: (err) => {
        customAlert(err.error.message,'warning')
      }
    })
  }

}


interface ApiResponse {
  success: boolean;
  message: string;
  data: Data;
  meta: Meta;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

interface Data {
  _id?: string;
  role?: {
    roleId?: {
      _id?: string;
      isDefault?: boolean;
      role?: string;
      __v?: number;
      createdAt?: string;
      description?: string;
      name?: string;
      resources?: string[];
      status?: string;
      updatedAt?: string;
    };
    roleType?: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  phone?: {
    countryCode:string | undefined;
    phoneNumber:string | undefined;
  } | undefined;
  address?: {
    street: string | undefined;
    city: string | undefined;
    state: string | undefined;
    country: string | undefined;
    zipCode: string | undefined;
    geo: number[];
  },
  dob?:string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  photo?: string;
}

interface PasswordData{
  oldPassword:string;
  newPassword:string;
  confirmPassword:string;
}