import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent {

  /**
   * Property to set title
   */
  @Input() titleValue = 'Modal Title';

  /**
   * Property to set delete popup
   */
  @Input() addPopup = false;

  /**
   * Property to set cancel popup
   */
  @Input() cancelPopup = false;

  /**
   * Property to set logout popup
   */
  @Input() logout = false;

  /**
   * Property to handle close click event
   */
  @Output() closeClick = new EventEmitter();

  /**
   * Property to handle outside click event
   */
  @Output() outsideClick = new EventEmitter();

  /**
   * Property to handle delete click event
   */
  @Output() deleteButton = new EventEmitter();

  /**
   * Property to handle logout click event
   */
  @Output() logoutButton = new EventEmitter();

  /**
   * Property to handle confirm click event
   */
  @Output() confirmButton = new EventEmitter();

  /**
   * Property to toggle css
   */
  @Input() toggleCss = false;

  /**
   * Method to trigger close event of modal
   */
  closeModal(): void {
    this.closeClick.emit(true);
  }

  /**
   * Method to handle delete click
   */
  deleteClick(): void {
    this.deleteButton.emit(true);
  }

  /**
   * Method to handle logout click
   */
  logoutClick(): void {
    this.logoutButton.emit(true);
  }

  /**
   * Method to handle logout click
   */
  confirmClick(): void {
    this.confirmButton.emit(true);
  }

  /**
   * Method to trigger outside click event of modal
   */
  outsideClicked(): void {
    this.outsideClick.emit(false);
  }

}
