import { Injectable } from '@angular/core';
import { NetworkService } from '../network/network.service';

@Injectable({
  providedIn: 'root'
})
export class OrgnizationManagementService{


  /**
   * Property to hold service url
   */
  url = "/auth/organisation";

  constructor(private network: NetworkService) { }

  /**
   * Method to get orgnization management data
   */
  getOrgnizationMangement(page: number, limit: number, search = '') {
    return this.network.get(`${this.url}?limit=${limit}&page=${page}&search=${search}`);
  }

  /**
   * Method to get single orgnization management data
   */
  getSingleOrgnizationMangement(id:string) {
    return this.network.get(`${this.url}/${id}`);
  }

  /**
   * Method to edit orgnization management data
   */
  editOrgnization(data:object) {
    return this.network.put(`${this.url}`, data);
  }

  /**
   * Method to add orgnization management data
   */
  addOrgnization(data:object){
    return this.network.post(`${this.url}`, data);
  }

  deleteOrgnization(data:object){
    return this.network.delete(`${this.url}`, data);
  }

  getUserOrg(data:object){
    return this.network.post(`/auth/user/my-users`, data);
  }
}
