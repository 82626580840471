import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { TableComponent } from './Shared/table/table.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrganizationManagementComponent } from './pages/organization-management/organization-management.component';
import { DetailsComponent } from './pages/organization-management/details/details.component';
import { ServiceManagementComponent } from './pages/service-management/service-management.component';
import { DetailSidebarComponent } from './Shared/detail-sidebar/detail-sidebar.component';
import { BillingCentralComponent } from './pages/billing-central/billing-central.component';
import { PersonalDetailsComponent } from './pages/personal-details/personal-details.component';
import { TeamUsersComponent } from './pages/team-users/team-users.component';
import { EditDetailsComponent } from './pages/organization-management/edit-details/edit-details.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LeadsComponent } from './pages/leads/leads.component';
import { AuthGuard } from './guards/auth.guard';
import { ResetSuccessComponent } from './pages/reset-success/reset-success.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'orders', component: OrdersComponent  },
  { path: 'organization-management', component: OrganizationManagementComponent , canActivate: [AuthGuard] },
  { path: 'service-management', component: ServiceManagementComponent , canActivate: [AuthGuard] },
  { path: 'organization-management/details', component: DetailsComponent , canActivate: [AuthGuard] },
  { path: 'organization-management/leads', component: LeadsComponent },
  { path: 'organization-management/edit-details/:id', component: EditDetailsComponent , canActivate: [AuthGuard] },
  { path: 'sidebar', component: DetailSidebarComponent },
  { path: 'table', component: TableComponent },
  { path: 'billing-central', component: BillingCentralComponent , canActivate: [AuthGuard] },
  { path: 'personal-details', component: PersonalDetailsComponent , canActivate: [AuthGuard] },
  { path: 'team-users', component: TeamUsersComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'reset-password-success/:role', component: ResetSuccessComponent },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
