import { Injectable } from '@angular/core';
import { NetworkService } from '../network/network.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceMappingService {

  
  /**
   * Property to hold service url
   */
  url = "/aggregation/services";
  url2="/aggregation/mapping"

  constructor(private network: NetworkService) { }

  /**
   * Method to get service mapping data
   */
  getServiceMapping() {
    return this.network.get(`${this.url}`);
  }

  /**
   * Method to get service data from orgnization
   */
  getServiceWithOrg(data:object){
    return this.network.post(`${this.url2}/my-services`,data);
  }

  /**
   * Method to post service data from orgnization
   */
  postServiceWithOrg(data:object){
    return this.network.post(`${this.url2}`,data);
  }

  /**
   * Method to delete service data from orgnization
   */
  deleteServiceWithOrg(data:object){
    return this.network.delete(`${this.url2}`,data);
  }

  /**
   * Method to update service data from orgnization
   */
  updateServiceWithOrg(data:object){
    return this.network.put(`${this.url2}`,data);
  }
}
