import { Component, OnInit } from '@angular/core';
import { BillingCentralService } from 'src/app/services/billing-central/billing-central.service';
import { customAlert } from 'src/assets/messages/messages';

@Component({
  selector: 'app-billing-central',
  templateUrl: './billing-central.component.html',
  styleUrls: ['./billing-central.component.scss']
})
export class BillingCentralComponent implements OnInit{

    /**
   * Property to hold table data size
   */
    public tableDataSize= 0;

    /**
     * Property to hold table page number
     */
    public pageNumber= 1;
  
    /**
    * Property to hold table page limit
    */
    public pageLimit= 10;

  /**
   * Property to display detail sidebar
   */
  openDetailSidebar = false;

  /**
   * Property to hold orgnization id
   */
  orgnizationId:string|undefined='';

  /**
   * Property to hold invoice details
   */
  invoiceDetails:InvoiceData={} as InvoiceData;

  /**
   * Property to hold search data
   */
  searchData='';

  /**
   * Property to hold billing table data
   */
  public billingTableData = {
    thead: [
      {
        id: 'invoice',
        text: 'Invoice ID',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: true,
      },
      {
        id: 'orgnization',
        text: 'Organization',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: true,
      },
      {
        id: 'billingPeriod',
        text: 'Billing Period',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'fileCount',
        text: 'Files Count',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'amount',
        text: 'Amount',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'status',
        text: 'Status',
        isSortable: true,
        type: 'status',
        shouldTrigger: false,
      },
    ],
    tbody: [{ invoice: '', billingPeriod: '', fileCount: '', amount: '', status: '' },
],
  };

  constructor(private billing: BillingCentralService) {

  }

  ngOnInit(): void {
    this.getBillingCentral('',this.pageNumber,this.pageLimit);
  }

  /**
   * Method to handle table actions
   */
  onActionButtonHandler(data: { actionType: string, index: number }) {
    const { actionType,index} = data;
    const organizationData:OrganizationDetails = this.billingTableData.tbody[index];
    switch (actionType) {
      case 'detail-modal':
        this.orgnizationId=organizationData._id;
        this.getInvoice();
        break;
      default:
        break;
    }
  }

  /**
   * Method to search 
   */
  onSearch(event:Event) {
    this.searchData=(event.target as HTMLInputElement).value;
    this.getBillingCentral(this.searchData,this.pageNumber,this.pageLimit);
  }

    /**
  * Method to fetch table data with pagination
  */
    public fetchAllWithPagination(pageNumber: number) {
      this.getBillingCentral(this.searchData, pageNumber, this.pageLimit);
    }

  /**
   * Method to get invoice details
   */
  getInvoice(){
    this.billing.getInvoice(this.orgnizationId)
    .subscribe({
      next: (response: InvoiceResponse) => {
        if (response.data) {
          this.invoiceDetails=response.data
          this.openDetailSidebar = true;
        }
      },
      error: () => {
        customAlert('Not Found','warning');
      }
    })
  }

  formatDate(dateString: Date): string {

    // Split the date string by spaces to extract day, month, and year.
    const dateParts = (dateString.toString()).split(' ');

    // Extract day and month from the date string.
    const day = dateParts[2];
    const month = dateParts[1];

    // Convert the month from its full name to the abbreviated three-letter format.
    const monthAbbreviation = this.getMonthAbbreviation(month);

    // Combine day and month to get the desired format.
    return `${day} ${monthAbbreviation}`;
  }

  getMonthAbbreviation(fullMonthName: string): string {
    // Define a mapping of full month names to abbreviated three-letter format.
    const monthMap: { [key: string]: string } = {
      "Jan": "Jan",
      "Feb": "Feb",
      "Mar": "Mar",
      "Apr": "Apr",
      "May": "May",
      "Jun": "Jun",
      "Jul": "Jul",
      "Aug": "Aug",
      "Sep": "Sep",
      "Oct": "Oct",
      "Nov": "Nov",
      "Dec": "Dec",
    };

    // Return the corresponding month abbreviation from the mapping.
    return monthMap[fullMonthName];
  }

  /**
* Method to get Billing central table data
*/
  private getBillingCentral(search: string, page: number, limit: number) {
    this.billing.getBillingCentral(page, limit, search)
      .subscribe((response: ApiResponse) => {
        if (response.data) {
          this.billingTableData.tbody = response.data.map((dataItem: any) => ({
        ...dataItem,
        invoice: dataItem.invoiceNumber,
        orgnization: dataItem._organization ? dataItem._organization.name : '',
        billingPeriod: `${this.formatDate(new Date(dataItem.timePeriod.startDate))} - ${this.formatDate(new Date(dataItem.timePeriod.endDate))}`,
        fileCount: dataItem.fileCount ? dataItem.fileCount : '0',
        amount: dataItem.amount.totalAmount ? `$${dataItem.amount.totalAmount}` : '0',
        status: dataItem.paymentStatus,
      }));
        }
        this.tableDataSize = response.meta.totalRecords;
      })
  }


}

interface TimePeriod{
  startDate:string,
  endDate:string
}
interface DataItem{
  invoice?:string,
  invoiceNumber:string,
  timePeriod:TimePeriod,
  paymentStatus:string,
  billingPeriod?:string,
  fileCount:string,
  amount:string,
  status:string,
  [key: string]: string | number | { time: number; units: string; } | undefined | { amount: number; units: string; } | TimePeriod | undefined ;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

interface ApiResponse {
  success: boolean;
  message: string;
  data: DataItem[];
  meta: Meta;
}


interface InvoiceResponse {
  success: boolean;
  message: string;
  data: InvoiceData;
  meta: Meta;
}

interface InvoiceData {
  _id: string;
  _organization: {
    createdBy: {
      _id: string;
      role: string;
    };
    _id: string;
    name: string;
    industry: string;
    billingEmail: string;
    supportEmail: string;
    address: {
      street: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      geo: [number, number];
      _id: string;
    };
    taxNumber: string;
    billing: boolean;
    email: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    uniqueId: string;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonNumber: {
      countryCode: string;
    };
  };
  amount: {
    totalAmount: number;
    units: string;
  };
  invoiceNumber: string;
  paymentStatus: string;
  timePeriod: {
    startDate: string;
    endDate: string;
  };
  fileCount: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
  orders: Order[];
  invoiceLink: string;
}

 interface Order {
  _id: string;
  name: string;
  orderNumber: string;
  _organization: string;
  _service: {
    _id: string;
    name: string;
    rate: {
      amount: number;
      units: string;
    };
  };
  orderDate: string;
  notes: string;
  status: string;
  createdBy: {
    _id: string | null;
    role: string;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
  billableImages: number | string;
}

interface OrganizationDetails {
  invoice: string;
  billingPeriod: string;
  fileCount: string;
  amount: string;
  status: string;
  _id?: string;
}