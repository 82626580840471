import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss']
})
export class CountryDropdownComponent {
  /**
   * Property to hold whether dropdown is open or not
   */
  public open = false;

  /**
   * Property to hold all countries
   */
  public countries: Country[] = [];
  public allCountries: Country[] = [];

  /**
   * Property to hold all flag values
   */
  public selectFlag = '';

  @Input() onlyCountry=false;

  /**
   * Property to hold selected text
   */
  @Input() selectedText:string | undefined = '+1';

    /**
   * Property to hold selected text
   */
    @Input() selectedName = 'Select Country';


    @Input() editEnable=false;

  /**
   * Property to hold upward and downward dropdown
   */
  @Input() top = false;

  /**
   * Property to emit the selected country value
   */
  @Output() selectCountry = new EventEmitter<string>();

    /**
   * Property to emit the selected country value
   */
    @Output() selectOnlyCountry = new EventEmitter<string>();

  constructor() {
    fetch('assets/statics/countries.json')
      .then(res => res.json())
      .then(countries => {
        for (const country of countries) {
          this.countries = [...this.countries, { ...country, flag: `assets/statics/flags/${country.code.toLowerCase()}.svg` }];
          this.allCountries = [...this.countries, { ...country, flag: `assets/statics/flags/${country.code.toLowerCase()}.svg` }];
        }
      });
  }

  /**
   * Method to show dropdown
   */
  public showList(event: Event): void {
    document.addEventListener('click', () => {
      this.hideList();
    });
    this.open = !this.open;
    event.stopPropagation();
  }

  /**
   * Method to hide the dropdown when a country is clicked
   */
  public hideList(): void {
    this.open = false;
    document.removeEventListener('click', () => {
      null;
    });
  }

  /**
   * Method to display the selected country on the button
   */
  public selectItem(event: Event, item: string, flag: string,name: string): void {
    const selectedText = item || '';
    this.selectedText = selectedText;
    this.selectFlag = flag;
    this.selectedName=name;
    this.hideList();
    this.selectCountry.emit(selectedText);
    this.selectOnlyCountry.emit(name);
    this.countries=this.allCountries;
    event.stopPropagation();
  }

  /**
   * Method to handle search bar functionality in dropdown 
   */
  public search(value: EventTarget | null): void {
    if (value instanceof HTMLInputElement) {
      this.countries = this.allCountries.filter(c => {
        return (c.name.toLowerCase().match(value.value.toLowerCase()) || String(c.dial_code).match(String(value.value)));
      });
    }
  }
  /**
   * Method to close the dropdown when clicked outside
   */
  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}

interface Country {
  name: string;
  code: string;
  flag: string;
  dial_code:string
}
