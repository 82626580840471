<div class="main-container">
    <div class="sidebar-container">
        <app-sidebar></app-sidebar>
    </div>

    <div class="table-container">
        <div class="heading-container">
            <div>
                <h2 class="module-heading">Organization Management</h2>
                <span class="module-sub-heading">View or update your <span style="font-weight: bold;">organization
                        details</span> here.</span>
            </div>
            <div>
                <button class="black-button" routerLink="/organization-management/details">New Organization</button>
            </div>
        </div>
        <div>
            <app-table 
            [tableData]="organizationManagementTableData" 
            [showActions]="false" 
            [showArrowButton]="true"
            (actionButtonHandler)="onActionButtonHandler($event)"
            [showPagination]="true"
            [tableDataSize]="tableDataSize"
            [pageSize]="pageLimit"
            [pageNumber]="pageNumber"
            (fetchAllWithPagination)="fetchAllWithPagination($event)"></app-table>
        </div>
    
    </div>
</div>
