import Swal, { SweetAlertIcon } from "sweetalert2";

const message: Record<string, string> = {
  add: 'Added Successfully',
  update: 'Updated successfully',
  delete: 'Deleted successfully',
  select: 'Select item',
  search: 'Wrong search',
  input: 'Wrong input',
  required: 'Required Value',
  invalidCredentials:'Invalid credentials',
  emailEnter:'Please enter email',
  passwordEnter:'Please enter password',
  emailValidation:'Please enter valid Email',
  fillAll: 'Please fill all the fields',
  nameValidation: 'Please enter valid name',
  phoneValidation: 'Please enter valid phone',
  addOrgnization: 'Organization added successfully',
  deleteService: 'Service deleted successfully',
  deleteOrganization: 'Organization deleted successfully',
  updateService: 'Service updated successfully',
  addService: 'Service added successfully',
  updatePassword: 'Password updated successfully',
  updateOrgnization: 'Organization updated successfully',
  updateProfile: 'Profile updated successfully',
  serviceNameEmpty: 'Please enter service name',
  serviceIdEmpty: 'Please enter service id',
  invalidTurnaroundTime: 'Please enter valid turnaround time',
  invalidAmount: 'Please enter valid amount',
  turnaroundTimeEmpty: 'Please enter turnaround time',
  amountEmpty: 'Please enter amount',
  descriptionEmpty: 'Please enter description',
  logout: 'Logout successfully',
  validPassword: 'Password must contain atleast 8 characters, 1 uppercase, lowercase, numeric value and special character',
};

const customToastCSS = `
  /* Custom CSS for the toast notification */
  .custom-toast.swal2-popup {
    margin:0px;
    //background:#212121;
    padding-top:6px;
    padding-bottom:6px;
    //color:white;
    font-family: Mulish;  /* Set font-family as desired */
  }
`;

// Inject the custom CSS into the document's head
const styleElement = document.createElement('style');
styleElement.innerHTML = customToastCSS;
document.head.appendChild(styleElement);


export const messageAlert = (key: string, icon: SweetAlertIcon) => {
  Swal.fire({
    toast: true,
    icon,
    position: 'top-end',
    width: `auto`,
    html: "<p style='font-style: 'Mulish', sans-serif'>" + message[key] + "</p>" ,
    timer: 3000,
    timerProgressBar: false,
    showConfirmButton: false,
    allowOutsideClick: true,
    customClass: 'custom-toast',
    showClass: {
      popup: `
        animate__animated
        animate__fadeInRight
        animate__slower
      `
    },
    hideClass: {
      popup: `
        animate__animated
        animate__fadeOutRight
        animate__slower
      `
    },
  });
};

export const customAlert = (key:string, icon: SweetAlertIcon) => {
  Swal.fire({
    toast: true,
    icon: icon,
    width: `auto`,
    position: 'top-end',
    html: "<p style='font-style: 'Mulish', sans-serif'>" + key + "</p>",
    timer: 1500,
    timerProgressBar: false,
    showConfirmButton: false,
    allowOutsideClick: true,
    customClass: 'custom-toast',
    showClass: {
      popup: `
        animate__animated
        animate__fadeInRight
        animate__slower
      `
    },
    hideClass: {
      popup: `
        animate__animated
        animate__fadeOutRight
        animate__slower
      `
    },
  })
}

