import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { OrgnizationManagementService } from 'src/app/services/orgnization-management/orgnization-management.service';
import { ServiceMappingService } from 'src/app/services/service-mapping/service-mapping.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  /**
   * Property to display service popup
   */
  addServicePopup = false;
  editServicePopup = false;
  enableBillingModule = false;
  showDropdown = false;
  showDropdownType = false;
  selectType = '';
  selectName = '';
  serviceDropdownClick: boolean[] = [];
  serviceDelete = false;

  /**
   * Object to hold form data
   */
  orgnizationData: OrgnizationData = {
    name: '',
    industry: '',
    contactPersonName: '',
    contactPersonNumber: {
      countryCode: '',
      phoneNumber: '',
    },
    contactPersonEmail: '',
    billingEmail: '',
    supportEmail: '',
    address: '',
    city: '',
    country:'',
    state: '',
    postCode: '',
    taxNumber: '',
    myServices: [],
    billing: false,
  };

  /**
   * Property to handle selected service
   */
  selectedService: ServiceList = {
    id: '',
    name: '',
    _id: '',
    createdAt: '',
  };

  /**
   * Property to hold delete service index
   */
  selectedIndexService = 0;

  /**
   * Property to handle selected service
   */
  confirmServices: ServiceList[] = [];

  /**
   * Property to hold new service data
   */
  serviceData: ServiceData = {
    serviceName: '',
    serviceId: '',
    turnaroundTime: '',
    rate: '',
  };

  /**
   * Property to hold service list
   */
  serviceList: ServiceList[] = [];

  /**
   * Property to display delete organization popup
   */
  deleteOrganization = false;

  /**
   * Property to display industry dropdown
   */
  industry_list = [
    'Aerospace and Defense',
    'Agriculture',
    'Automotive',
    'Banking and Finance',
    'Biotechnology and Pharmaceuticals',
    'Chemicals',
    'Construction',
    'Consumer Goods',
    'Education',
    'Energy and Utilities',
    'Entertainment and Media',
    'Food and Beverage',
    'Government and Public Sector',
    'Healthcare',
    'Hospitality and Tourism',
    'Information Technology (IT)',
    'Manufacturing',
    'Non-Profit and Charity',
    'Real Estate',
    'Retail',
    'Telecommunications',
    'Transportation and Logistics',
  ];

  constructor(
    private orgnizationManagement: OrgnizationManagementService,
    private route: Router,
    private serviceMapping: ServiceMappingService
  ) {}

  ngOnInit(): void {
    this.getServiceList();
    this.services_List=this.serviceList;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const dropdownElement = document.querySelector('.dropdown');
    if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
      this.showDropdown = false;
    }
  }

  /**
   * Method to display delete organization popup
   */
  onDeleteOrganization() {
    this.deleteOrganization = true;
  }

  @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef;

  isScrollbarAtEnd = true  ;

  checkScrollbar() {
    const element = this.scrollContainer.nativeElement;
    const atEnd = element.scrollTop === (element.scrollHeight - element.clientHeight);
    if(element.scrollTop === 0){
      this.isScrollbarAtEnd = !atEnd;
    }else{
      this.isScrollbarAtEnd = atEnd;
    }
  } 

  /**
   * Method to open add service
   */
  openAddService() {
    this.addServicePopup = true;
    this.selectedService = {
      id: '',
      name: '',
      _id: '',
      createdAt: '',
    };
    this.selectName = '';
    this.selectType = '';
    this.services_List=this.serviceList;
  }

  /**
   * Method to get dropdown value
   */
  dropdownValue(event: string) {
    this.orgnizationData.industry = event;
    this.showDropdown = false;
  }

  /**
   * Method to delete service mapping
   */
  deleteService(index: number) {
    this.confirmServices.splice(index, 1);
    this.serviceDelete = false;
  }

  deleteServiceName = '';

  /**
   * Method to handle service dropdown edit
   */
  serviceDropdownValue(text: string, index: number) {
    if (text == 'delete') {
      this.serviceDropdownClick[index] = false;
      this.selectedIndexService = index;
      this.deleteServiceName = this.confirmServices[index].name;
      this.serviceDelete = true;
    } else if (text == 'edit') {
      this.selectName = this.confirmServices[index].name;
      this.selectType = this.confirmServices[index].id;
      this.serviceDropdownClick[index] = false;
      this.selectedIndexService = index;
      this.editServicePopup = true;
    }
  }

  /**
   * Method to get dropdown value
   */
  dropdownTypeValue(service: ServiceList) {
    this.selectType = service.id;
    this.selectName = service.name;
    this.showDropdownType = false;
    this.selectedService = {
      id: service.id,
      name: service.name,
      createdAt: service.createdAt,
      _id: service._id,
    };
  }

  /**
   * Method to set input value
   */
  getInputVale(event: Event) {
    if ((event.target as HTMLInputElement).name === 'contactPersonNumber') {
      this.orgnizationData.contactPersonNumber.phoneNumber = (
        event.target as HTMLInputElement
      ).value;
    } else {
      this.orgnizationData[(event.target as HTMLInputElement).name] = (
        event.target as HTMLInputElement
      ).value;
    }
  }

  onChecked(event: Event) {
    if (!(event.currentTarget as HTMLInputElement).checked) {
      this.enableBillingModule = true;
      (event.currentTarget as HTMLInputElement).checked = true;
    } else {
      this.enableBillingModule = true;
      (event.currentTarget as HTMLInputElement).checked = false;
    }
  }

  onEnableBillingModule() {
    this.orgnizationData.billing = !this.orgnizationData.billing;
    this.enableBillingModule = false;
  }

  /**
   * Method to get input value
   */
  getServiceValue(event: Event) {
    this.serviceData[(event.target as HTMLInputElement).name] = (
      event.target as HTMLInputElement
    ).value;
  }

  /**
   * Method to get Service List
   */
  getServiceList() {
    this.serviceMapping.getServiceMapping().subscribe({
      next: (data: MyData) => {
        data.data.forEach((element: MyDataItem) => {
          this.serviceList.push({
            name: element.name,
            id: element.serviceId,
            createdAt: element.createdAt,
            _id: element._id,
          });
        });
      },
    });
  }

  filted_industry = this.industry_list;

  searchIndusry(event: Event) {
    this.showDropdown=true;

    const searchText = (event.target as HTMLInputElement).value;
    this.filted_industry = this.industry_list.filter((item) => {
      return item.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  services_List :ServiceList[]=[];

  searchService(event: Event) {
    this.showDropdownType=true;
    this.services_List=this.serviceList;
    const searchText = (event.target as HTMLInputElement).value;
    this.services_List = this.services_List.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  /**
   * Method to confirm selected service
   */
  confirmService(selectedService: ServiceList) {
    if (this.editServicePopup) {
      if (
        this.confirmServices.some((obj) =>
          obj.name.includes(selectedService.name)
        )
      ) {
        customAlert('Service already exists', 'warning');
      } else {
        this.confirmServices.forEach((element, index) => {
          if (index == this.selectedIndexService) {
            this.confirmServices[index] = selectedService;
          }
        });
        this.editServicePopup = false;
        this.selectName = '';
        this.selectType = '';
      }
    } else {
      if (this.selectedService.name == '') {
        customAlert('Please select service', 'warning');
      } else if (
        this.confirmServices.some((obj) =>
          obj.name.includes(selectedService.name)
        )
      ) {
        customAlert('Service already exists', 'warning');
      } else {
        this.confirmServices.push(selectedService);
        this.addServicePopup = false;
        this.selectName = '';
        this.selectType = '';
      }
    }
  }

  /**
   * Method to Add a orgnization
   */
  addOrgnization(data: OrgnizationData) {
    const organizationSelectedServices = this.confirmServices.map((element) => {
      return element._id;
    });
    if (this.checkValidation(data) !== 'false') {
      customAlert(this.checkValidation(data), 'warning');
    } else if (!this.confirmServices.length) {
      customAlert('Please select at least one service', 'warning');
    } else if (
      this.textValidation(data.name) &&
      this.textValidation(data.contactPersonName)
    ) {
      messageAlert('nameValidation', 'warning');
    } else if (this.emailValidation(data.contactPersonEmail)) {
      messageAlert('emailValidation', 'warning');
    } else if (this.phoneValidation(data.contactPersonNumber.phoneNumber)) {
      messageAlert('phoneValidation', 'warning');
    } else {
      const body = {
        name: data.name,
        industry: data.industry,
        billingEmail: data.billingEmail,
        supportEmail: data.supportEmail,
        address: {
          street: data.address,
          city: data.city,
          state: data.state,
          country: data.country?data.country:'',
          zipCode: data.postCode,
          geo: [72.151, 36.84784],
        },
        contactPersonName: data.contactPersonName,
        contactPersonEmail: data.contactPersonEmail,
        contactPersonNumber: {
          countryCode: data.contactPersonNumber.countryCode
            ? data.contactPersonNumber.countryCode
            : '+1',
          phoneNumber: data.contactPersonNumber.phoneNumber,
        },
        taxNumber: data.taxNumber,
        myServices:
          this.confirmServices.length > 0
            ? organizationSelectedServices
            : data.myServices,
        billing: data.billing,
      };
      this.orgnizationManagement.addOrgnization(body).subscribe({
        next: (data: ApiResponse) => {
          if (data.success) {
            setTimeout(() => {
              messageAlert('addOrgnization', 'success');
            }, 1000);

            this.route.navigate(['/organization-management']);
          }else{
            customAlert(data.message, 'warning');
          }
        },
        error: (err) => {
          customAlert(err.error.message, 'warning');
        },
      });
    }
  }

  /**
   * Method to check validation
   */

  checkValidation(data: OrgnizationData) {
    if (data.name === '') {
      return 'Please enter organization name';
    } else if (data.industry === '') {
      return 'Please select industry';
    } else if (data.billingEmail === '') {
      return 'Please enter billing email';
    } else if (data.supportEmail === '') {
      return 'Please enter support email';
    } else if (data.address === '') {
      return 'Please enter organization address';
    } else if (data.city === '') {
      return 'Please enter city';
    } else if (data.state === '') {
      return 'Please enter state';
    } else if (data.country === '') {
      return 'Please select country';
    }else if (data.postCode === '') {
      return 'Please enter postcode';
    } else if (data.taxNumber === '') {
      return 'Please enter organization tax number';
    } else if (data.contactPersonName === '') {
      return 'Please enter contact person name';
    } else if (data.contactPersonNumber.phoneNumber === '') {
      return 'Please enter contact person number';
    } else if (data.contactPersonEmail === '') {
      return 'Please enter contact person email';
    } else if (this.emailValidation(data.supportEmail)) {
      return 'Please enter valid support email';
    } else if (this.emailValidation(data.billingEmail)) {
      return 'Please enter valid billing email';
    } else if (this.isValidTaxNumber(data.taxNumber)) {
      return 'Invalid organization tax number';
    } else if (data.taxNumber.length > 50) {
      return 'Invalid organization tax number';
    } else {
      return 'false';
    }
  }

  

  /**
   * Method to select country code
   */
  selectedCountryCode(countryCode: string) {
    this.orgnizationData.contactPersonNumber.countryCode = countryCode;
  }

    /**
   * Method to select country code
   */
    selectedCountries(country: string) {
      this.orgnizationData.country = country;
    }

  /**
   * Method to validate text
   */
  textValidation(text: string): boolean {
    const textValue = text.trim();
    if (textValue == '' || textValue.length > 50) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Method to validate tax number
   */
  isValidTaxNumber(taxNumber: string) {
    // Regular expression to match only capital letters or numbers
    const regex = /^[A-Za-z0-9]+$/;

    if (regex.test(taxNumber)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Method to validate email
   */
  emailValidation(email: string): boolean {
    const textValue = email.trim();

    // eslint-disable-next-line no-useless-escape
    const pattern =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (textValue.match(new RegExp(pattern)) == null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Method to validate phone
   */
  phoneValidation(phone: string): boolean {
    const textValue = phone.trim();
    if (textValue == '' || textValue.length > 10 || textValue.length < 10) {
      return true;
    } else {
      return false;
    }
  }
}

interface ServiceList {
  name: string;
  id: string;
  createdAt: string;
  _id: string;
}

interface OrgnizationData {
  name: string;
  industry: string;
  contactPersonName: string;
  contactPersonNumber: Phone;
  contactPersonEmail: string;
  billingEmail: string;
  supportEmail: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  country?: string;
  taxNumber: string;
  billing: boolean;
  myServices: string[];
  [key: string]: string | boolean | undefined | number | string[] | Phone;
}

interface Phone {
  countryCode: string;
  phoneNumber: string;
}

interface MyData {
  success: boolean;
  message: string;
  data: MyDataItem[];
  meta: {
    currentPage: number;
    totalPage: number;
    totalRecords: number;
  };
}

interface MyDataItem {
  turnaroundTime: {
    time: number;
    units: string;
  };
  rate: {
    amount: number;
    units: string;
  };
  createdBy: {
    _id: string;
    role: string;
  };
  _id: string;
  name: string;
  serviceId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface AddOrgnization {
  name: string;
  industry: string;
  contactPersonName: string;
  contactPersonPhone: string;
  email: string;
  billingEmail: string;
  supportEmail: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  taxNumber: string;
  billing: boolean;
  myServices: string[];
}

interface ApiResponse {
  success: boolean;
  message: string;
  data: AddOrgnization;
  meta: Meta;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

interface ServiceData {
  serviceName: string;
  serviceId: string;
  turnaroundTime: string;
  rate: string;
  [key: string]: string | undefined;
}
