import { Component } from '@angular/core';

@Component({
  selector: 'app-team-users',
  templateUrl: './team-users.component.html',
  styleUrls: ['./team-users.component.scss']
})
export class TeamUsersComponent {
  /**
   * Property to hold toggle password
   */
  togglePassword=false;
}
