import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service/auth-service.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetToken:string|null = '';
  togglePassword=false;
  togglePassword2=false;
  /**
   * Property to hold forgot password form
   */
  forgotPasswordForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    confirmPassword: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      this.resetToken = params.get('token');
    });
  }

  constructor(private authService: AuthServiceService, private route: Router, private location: Location,private activeRoute: ActivatedRoute) { }

  /**
   * Method to Forgot password
   */
  public forgotPassword() {
    const pattern=/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&*!~_+=.-])[A-Za-z\d@#$%^&*!~_+=.-]{8,}$/
    if (this.forgotPasswordForm.value.password == '') {
      customAlert("Please enter new password", 'warning');
    } else if (this.forgotPasswordForm.value.confirmPassword == '') {
      customAlert("Please enter confirm password", 'warning');
    }  else if(this.forgotPasswordForm.value.password?.match(new RegExp(pattern)) == null) {
      messageAlert("validPassword", 'warning');
    }else if(this.forgotPasswordForm.value.confirmPassword?.match(new RegExp(pattern)) == null) {
      messageAlert("validPassword", 'warning');
    }else if (this.forgotPasswordForm.value.password !== this.forgotPasswordForm.value.confirmPassword) {
      customAlert("Password and Confirm passwords do not match", 'warning');
    } else if (this.forgotPasswordForm.value.password === this.forgotPasswordForm.value.confirmPassword) {
      const body = {
        resetToken: this.resetToken,
        newPassword: this.forgotPasswordForm.value.password || "",
        confirmPassword: this.forgotPasswordForm.value.confirmPassword || "",
      }
      this.authService.resetPassword(body)
        .subscribe({
          next: (resetData: object) => {
            const data = resetData as ApiResponse; // Type assertion
            customAlert("Password reset successfully", 'success');
            if(data.data.role.roleType === 'SUPERADMIN'){
              this.route.navigate([`/reset-password-success/${data.data.role.roleType}`]);
            }else{
              this.forgotPasswordForm.reset();
              this.route.navigate([`/reset-password-success/${data.data.role.roleType}`]);
            }
          },
          error: (err) => {
            customAlert(err.error.message,'warning');
          }
        })
    }

  }

}

interface Role {
  _id: string;
  isDefault: boolean;
  role: string;
  __v: number;
  createdAt: string;
  description: string;
  name: string;
  resources: string[];
  status: string;
  updatedAt: string;
}

interface RoleData {
  roleId: Role;
  roleType: string;
}

interface ApiResponse {
 data: {role: RoleData;}
}