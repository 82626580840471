import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service/auth-service.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /**
   * Property to hold toggle password
   */
  togglePassword=false;

  /**
   * Property to hold login form
   */
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,})/gm)])
  });

  /**
   * Property to hold keep login
   */
  keepLoggedIn = false;
  

  constructor(private authService: AuthServiceService, private route: Router) { }

  /**
   * Init method
   */
  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.route.navigate(['/service-management']);
    }
  }

  /**
   * Method to login
   */
  public loginSubmit() {
    if(this.loginForm.value.email==''){
      messageAlert("emailEnter",'warning');
    }else if(this.loginForm.value.password==''){
      messageAlert("passwordEnter",'warning');
    } else{
      this.authService.login({
        email: this.loginForm.value.email || "",
        password: this.loginForm.value.password || ""
      }, this.keepLoggedIn)
        .subscribe({
          next: (data: object) => {
            const logindata = data as ApiResponse; // Type assertion
            this.authService.setToken(logindata.data.accessToken);
            if(this.keepLoggedIn){
              localStorage.setItem('refreshToken', logindata.data.refreshToken);
            }
            this.route.navigate(['/service-management']);
          },
          error: (err) => {
            if(err.status==401){
              this.getNewToken();
            }
            customAlert("Please enter valid details",'warning');
          }
        })
    }

  }

  getNewToken(){
    this.authService.getNewToken().subscribe({
      next: (data: object) => {
        const logindata = data as ApiResponse; // Type assertion
        this.authService.setToken(logindata.data.accessToken);
      },
      error: () => {
        customAlert("invalidCredentials",'warning');
      }
    })
  }


  /**
   * Method to toggle password
   */
  togglePasswordFn(){
    this.togglePassword=!this.togglePassword;
  }

}

interface LoginData {
  _id: string;
  role: {
    roleId: {
      _id: string;
      isDefault: boolean;
      role: string;
      __v: number;
      createdAt: string;
      description: string;
      name: string;
      resources: string[];
      status: string;
      updatedAt: string;
    };
    roleType: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  createdAt: string;
  photo: string;
  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    geo: number[];
  };
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
  dob: string;
  accessToken: string;
  refreshToken: string;
}

interface ApiResponse {
  success: boolean;
  message: string;
  data: LoginData;
  meta: Meta;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}
