
import { Component, OnInit } from '@angular/core';
import { ServiceManagementService } from 'src/app/services/service-management/service-management.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';

@Component({
  selector: 'app-service-management',
  templateUrl: './service-management.component.html',
  styleUrls: ['./service-management.component.scss']
})
export class ServiceManagementComponent implements OnInit {

  /**
   * Property to hold table data size
   */
  public tableDataSize = 0;

  /**
   * Property to hold table page number
   */
  public pageNumber = 1;

  /**
  * Property to hold table page limit
  */
  public pageLimit = 10;
  /**
   * Property to display table
   */
  public serviceManagementTableData = {
    thead: [
      {
        id: 'serviceName',
        text: 'Service Name',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'serviceId',
        text: 'Service ID',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'time',
        text: 'Turnaround Time',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'price',
        text: 'Price',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'status',
        text: 'Status',
        isSortable: true,
        type: 'status',
        shouldTrigger: false,
      },
    ],
    tbody: [{}],
  };

  /**
   * Property to hold search data
   */
  searchData = '';

  /**
   * Property to display service popup
   */
  addService = false;
  editService = false;
  deletepopup = false;
  serviceId: string | undefined = '';
  serviceName: string | undefined = '';

  /**
   * Property to display delete service popup
   */
  deleteService = false;

  /**
   * Property to hold new service data
   */
  serviceData: ServiceData = {
    serviceName: '',
    serviceId: '',
    turnaroundTime: {
      time: 0,
      units: ''
    },
    rate: {
      amount: 0,
      units: ''
    },
    description: '',
  }

  /**
   * Constructor
   */
  constructor(private serviceMangement: ServiceManagementService) {

  }

  ngOnInit(): void {
    this.getServiceManagement('', 1, 10)
  }

  /**
   * Method to display detail sidebar
   */
  onActionButtonHandler(data: { actionType: string; index: number; }) {
    const { actionType, index } = data;
    const serviceTableData: TableDataService = this.serviceManagementTableData.tbody[index] as TableDataService
    this.serviceId = serviceTableData._id;
    this.serviceName= serviceTableData.serviceName;
    switch (actionType) {
      case 'edit':
        this.editService = true;
        this.serviceData.serviceName = serviceTableData.serviceName;
        this.serviceData.serviceId = serviceTableData.serviceId;
        this.serviceData.turnaroundTime.time = (serviceTableData.turnaroundTime.time);
        this.serviceData.rate.amount = (serviceTableData.rate.amount)
        this.serviceData.description = serviceTableData.description
        break;
      case 'delete':
        this.deletepopup = true;
        break;
      default:
        break;
    }
  }


  /**
  * Method to fetch table data with pagination
  */
  public fetchAllWithPagination(pageNumber: number) {
    this.getServiceManagement('', pageNumber, this.pageLimit);
  }
  /**
   * Method to round to two decimal places
   */
  roundToTwoDecimalPlaces(number: number) {
    return Math.round(number * 100) / 100;
  }

  /**
   * Method to display delete service popup
   */
  onDeleteService() {
    this.deleteService = true
  }

  /**
   * Method to display add service popup
   */
  onAddService() {
    this.addService = true
  }

  /**
   * Method to get service value
   */
  getServiceValue(event: Event) {
    if ((event.target as HTMLInputElement).name === 'turnaroundTime') {
      this.serviceData.turnaroundTime = {
        time: parseFloat((event.target as HTMLInputElement).value),
        units: 'HOURS'
      };
    } else if ((event.target as HTMLInputElement).name === 'rate') {
      this.serviceData.rate = {
        amount: parseFloat((event.target as HTMLInputElement).value),
        units: 'USD'
      };
    } else {
      this.serviceData[(event.target as HTMLInputElement).name] = (event.target as HTMLInputElement).value;
    }
  }

  /**
   * Method to handle search
   */
  onSearch(event: Event) {
    this.searchData = (event.target as HTMLInputElement).value;
    this.getServiceManagement(this.searchData, this.pageNumber, this.pageLimit);
  }

  /**
   * Method to add service
   */
  addServiceMangement(data: ServiceData) {
    if (data.serviceName.trim().length === 0) {
      messageAlert('serviceNameEmpty', 'warning');
    } else if (data.serviceId.trim().length === 0) {
      messageAlert('serviceIdEmpty', 'warning');
    } else if (typeof data.turnaroundTime.time !== 'number') {
      messageAlert('invalidTurnaroundTime', 'warning');
    } else if (typeof data.rate.amount !== 'number' || this.hasMoreThanTwoDigitsAfterDecimal(data.rate.amount)) {
      messageAlert('invalidAmount', 'warning');
    }else if (data.turnaroundTime.time === 0) {
      messageAlert('turnaroundTimeEmpty', 'warning');
    } else if (data.rate.amount === 0) {
      messageAlert('amountEmpty', 'warning');
    } else if (data.description === '' ) {
      messageAlert('descriptionEmpty', 'warning');
    } else {
      
      const body = {
        name: data.serviceName,
        serviceId: data.serviceId,
        turnaroundTime: {
          time: data.turnaroundTime.time,
          units: "HOURS"
        },
        rate: {
          amount: data.rate.amount,
          units: "USD"
        },
        description: data.description
      }
  
      this.serviceMangement.addServiceMangement(body).subscribe({
        next: (data: ApiResponse) => {
          if (data.success) {
            setTimeout(() => {
              this.addService = false;
              this.serviceData = {
                serviceName: '',
                serviceId: '',
                turnaroundTime: {
                  time: 0,
                  units: ''
                },
                rate: {
                  amount: 0,
                  units: ''
                },
                description: ''
              }
              messageAlert("addService", 'success');
            }, 1000);
  
            this.getServiceManagement('', this.pageNumber, this.pageLimit);
          }
        },
        error: (err) => {
          customAlert(err.error.message, 'warning');
          
        }
      })
      
    }

  }


  /**
   * Method to edit service
   */
  editServiceMangement(data: ServiceData) {
    if (data.serviceName.trim().length === 0) {
      messageAlert('serviceNameEmpty', 'warning');
    } else if (data.serviceId.trim().length === 0) {
      messageAlert('serviceIdEmpty', 'warning');
    } else if (typeof data.turnaroundTime.time !== 'number') {
      messageAlert('invalidTurnaroundTime', 'warning');
    } else if (typeof data.rate.amount !== 'number' || this.hasMoreThanTwoDigitsAfterDecimal(data.rate.amount)) {
      messageAlert('invalidAmount', 'warning');
    }else if (data.turnaroundTime.time === 0 || isNaN(data.turnaroundTime.time)) {
      messageAlert('turnaroundTimeEmpty', 'warning');
    } else if (data.rate.amount === 0 || isNaN(data.rate.amount)) {
      messageAlert('amountEmpty', 'warning');
    } else if (data.description === '' ) {
      messageAlert('descriptionEmpty', 'warning');
    } else {
      this.editService = false;
      const turnaroundTimeValue = data.turnaroundTime.time;
      const amountValue = data.rate.amount;
      const body = {
        _id: this.serviceId,
        name: data.serviceName,
        serviceId: data.serviceId,
        turnaroundTime: {
          time: turnaroundTimeValue,
          units: "HOURS"
        },
        rate: {
          amount: amountValue,
          units: "USD"
        },
        description: data.description
      }
  
      this.serviceMangement.editServiceMangement(body).subscribe({
        next: (data: ApiResponse) => {
          if (data.success) {
            setTimeout(() => {
              messageAlert("updateService", 'success');
            }, 1000);
            this.getServiceManagement('', this.pageNumber, this.pageLimit);
          }
        },
        error: (err) => {
          customAlert(err.error.message, 'warning');
        }
      }) 
      this.serviceData = {
        serviceName: '',
        serviceId: '',
        turnaroundTime: {
          time: 0,
          units: ''
        },
        rate: {
          amount: 0,
          units: ''
        },
        description: ''
      }
    }

  }

  hasMoreThanTwoDigitsAfterDecimal(number: number) {
    const decimalPart = number.toString().split('.')[1];
    return decimalPart ? decimalPart.length > 2 : false;
  }

  extractFloatFromString(str: string) {
    const regex = /\d+/; // Regular expression to match one or more digits
    const match = str.match(regex); // Extract the first number from the string

    if (match) {
      // If a number is found, return it as a numeric value
      return Number(match[0]);
    } else {
      // If no number is found, return null or any other appropriate value
      return null;
    }
  }

  /**
   * Method to delete service
   */
  deleteServiceMangement() {
    this.deletepopup = false;
    const body = {
      _id: this.serviceId
    }

    this.serviceMangement.deleteServiceMangement(body).subscribe({
      next: (data: ApiResponse) => {
        if (data.success) {
          setTimeout(() => {
            messageAlert("deleteService", 'success');
          }, 1000);

          this.getServiceManagement('', this.pageNumber, this.pageLimit);
        }
      },
      error: (err) => {
        customAlert(err.error.message, 'warning');
      }
    })
  }


  /**
   * Method to get service management table data
   */
  private getServiceManagement(search: string, page: number, limit: number) {
    this.serviceMangement.getServiceMangement(page, limit, search)
      .subscribe((response: ApiResponse) => {
        if (response.data) {
          this.serviceManagementTableData.tbody = response.data.map((dataItem: ServiceData) => (
            {
              ...dataItem,
              serviceName: dataItem.name,
              serviceId: dataItem.serviceId,
              time: dataItem.turnaroundTime.time + " " + "hrs",
              price: "$"+dataItem.rate.amount,
              status: dataItem.status,
            }
          ))
        }

        this.tableDataSize = response.meta.totalRecords;
      })
  }
}

interface ServiceData {
  serviceName: string,
  serviceId: string,
  turnaroundTime: TurnaroundTime
  name?: string;
  status?: string;
  rate: Rate,
  _id?: string,
  price?: string,
  description?: string
  [key: string]: string | number | { time: number; units: string; } | undefined | { amount: number; units: string; };
}

interface TurnaroundTime {
  time: number,
  units: string
}

interface Rate {
  amount: number,
  units: string
}

interface ApiResponse {
  success: boolean;
  message: string;
  data: ServiceData[];
  meta: Meta;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

export interface TableDataService {
  turnaroundTime: TurnaroundTime
  rate: Rate
  createdBy: CreatedBy | string
  _id: string
  name: string
  serviceId: string
  description: string
  status: string
  createdAt: string
  updatedAt: string
  __v: number
  serviceName: string
  price: string,
  [key: string]: string | number | { time: number; units: string; } | undefined | { amount: number; units: string; } | CreatedBy
}

export interface CreatedBy {
  _id: string
  role: string
}

