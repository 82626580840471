import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrgnizationManagementService } from 'src/app/services/orgnization-management/orgnization-management.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {

  /**
   * Property to display service popup
   */
  openEditForm = false;

  /**
   * Property to hold table data size
   */
  public tableDataSize= 0;

  /**
   * Property to hold table page number
   */
  public pageNumber= 1;

  /**
  * Property to hold table page limit
  */
  public pageLimit= 10;

  /**
   * Property to display table
   */
  public leadsTableData:TableData = {
    thead: [
      {
        id: 'organizationName',
        text: 'Organization Name',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'industry',
        text: 'Industry',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      // {
      //   id: 'websiteUrl',
      //   text: 'Website Url',
      //   isSortable: true,
      //   type: 'inputText',
      //   shouldTrigger: false,
      // },
      {
        id: 'email',
        text: 'Email',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'phone',
        text: 'Phone',
        isSortable: true,
        type: 'inputText',
        shouldTrigger: false,
      },
      {
        id: 'status',
        text: 'Status',
        isSortable: true,
        type: 'status',
        shouldTrigger: false,
      },
    ],
    tbody: [

    ],
  };

  constructor(private orgnizationManagement: OrgnizationManagementService, private route: Router) {

  }

  ngOnInit(){
    this.getLeads('', this.pageNumber, this.pageLimit);
  }

  /**
   * Method to display detail sidebar
   */
  onActionButtonHandler(data: { actionType: string, index: number }) {
    const { actionType, index } = data;
    let organizationData:{_id:string};
    switch (actionType) {
      case 'next-edit':
        this.openEditForm = true;
        organizationData=this.leadsTableData.tbody[index]; 
        this.route.navigate([`/organization-management/edit-details/${organizationData._id}`]);
        break;
      default:
        break;
    }
  }

  /**
  * Method to fetch table data with pagination
  */
  public fetchAllWithPagination(pageNumber: number) {
    this.getLeads('', pageNumber, this.pageLimit);
  }

  /**
   * Method to get orgnization management table data
   */
  private getLeads(search: string, page: number, limit: number) {
    this.orgnizationManagement.getOrgnizationMangement(page, limit, search)
      .subscribe((response: ApiResponse) => {
        if (response.data) {
          this.leadsTableData.tbody = response.data.map((dataItem: DataItem) => (
            {
              ...dataItem,
              contactName: (dataItem.contactPerson[0]?.firstName ? dataItem.contactPerson[0]?.firstName.charAt(0).toUpperCase() + dataItem.contactPerson[0]?.firstName.slice(1) : '') + ' ' + (dataItem.contactPerson[0]?.lastName ? dataItem.contactPerson[0]?.lastName : ''),
              organizationName: dataItem.name,
              date: (new Date(dataItem.createdAt).toLocaleDateString("en-GB")),
              email: dataItem.contactPerson[0]?.email?dataItem.contactPerson[0]?.email:'', 
              status:'REJECTED',
            }
          ))
        }
        this.tableDataSize = response.meta.totalRecords;
      })
  }
}


interface CreatedBy {
  _id: string;
  role: string;
}

interface Address {
  street: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  geo: number[];
  _id: string;
}

interface ContactPerson {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Phone {
  countryCode: string;
  phoneNumber: string;
  _id: string;
}

interface DataItem {
  createdBy: CreatedBy;
  _id: string;
  name: string;
  industry: string;
  billingEmail: string;
  supportEmail: string;
  address: Address;
  taxNumber: string;
  billing: boolean;
  email: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  contactPerson: ContactPerson[];
  totalUsers: number;
  totalServices: number;
  url?: string;
  phone?: Phone;
}

interface Meta {
  currentPage: number;
  totalPage: number;
  totalRecords: number;
}

interface ApiResponse {
  success: boolean;
  message: string;
  data: DataItem[];
  meta: Meta;
}

interface ContactPerson {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface CreatedBy {
  _id: string;
  role: string;
}

interface Phone {
  countryCode: string;
  phoneNumber: string;
  _id: string;
}

interface Data {
  createdBy: CreatedBy;
  _id: string;
  name: string;
  industry: string;
  billingEmail: string;
  supportEmail: string;
  address: Address;
  taxNumber: string;
  billing: boolean;
  email: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  contactPerson: ContactPerson[];
  totalUsers: number;
  totalServices: number;
  url?: string;
  phone?: Phone;
}

export interface TableData {
  thead: TableColumn[];
  tbody: Data[]; 
}

export interface TableColumn {
  id: string;
  text: string;
  isSortable: boolean;
  type: string;
  shouldTrigger: boolean;
}